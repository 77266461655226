import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'

const StyledSearchButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
`

const StyledSearchButtonWithHover = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Search', -6)}
`

export default function SearchButton (props) {
    if (props.hover) {
        return (
            <StyledSearchButtonWithHover {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <i className='material-icons' style={props.style}>search</i>
            </StyledSearchButtonWithHover>
        )
    } else {
        return (
            <StyledSearchButton {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <i className='material-icons' style={props.style}>search</i>
            </StyledSearchButton>
        )
    }
}
