import styled from 'styled-components'
import * as styles from 'styles'

const StyledTextarea = styled.textarea`
    font-family: 'Ubuntu', sans-serif;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 4px 6px;
    border-radius: 4px;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 2px 2px ${styles.inputShadow};
    outline: none;
    :focus {
        background-color: ${styles.inputFocus};
    }
`

export default StyledTextarea
