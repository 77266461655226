import React, { useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import HomePage from 'views/Home'
import DrawView from 'views/Draw'
import EquationView from 'views/Equation'
import SheetView from 'views/Sheet'
import DataView from 'views/Data'
import Header from 'components/Header'
import Help from 'components/Help'
import HelpButton from 'components/buttons/Help'
import HelpSheet from 'components/HelpSheet'
import HelpEquation from 'components/HelpEquation'
// import Footer from 'components/Footer'

/*
    Extra small devices
    @media (max-width:480px) {
    }
    Small devices
    @media (max-width:768px) {
    }
    Medium devices
    @media (max-width:992px) {
    }
    Large devices
    @media (max-width:1200px) {
    }
*/

const StyledApp = styled.div`
    font-family: 'Ubuntu', sans-serif;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    > div {
        flex-grow: 1;
    }

    input {
        font-family: 'Ubuntu', sans-serif;
        border: none;
    }
`

const Menu = styled.div`
    display: flex;
    i {
        font-size: 3rem;
    }
`

const MenuButton = styled.div`
    padding: 5px;
`

export default function App (props) {
    const [showHelp, setShowHelp] = useState(false)

    function toggleHelp () {
        setShowHelp(!showHelp)
    }

    return (
        <StyledApp>
            <BrowserRouter>
                <Header>
                    <Route path='/(eq|sheet)' render={props => <Menu>
                        <MenuButton>
                            <HelpButton hover active={showHelp} onClick={toggleHelp} />
                        </MenuButton>
                    </Menu>} />
                </Header>
                <Switch>
                    <Route path='/draw' render={props => <DrawView {...props} toggleHelp={toggleHelp} />}/>
                    <Route path='/eq' render={props => <EquationView {...props} toggleHelp={toggleHelp} />}/>
                    <Route path='/sheet' render={props => <SheetView {...props} toggleHelp={toggleHelp} />} />
                    <Route path='/data' render={props => <DataView {...props} toggleHelp={toggleHelp} />}/>
                    <Route path='/' render={props => <HomePage {...props} toggleHelp={toggleHelp} />}/>
                </Switch>
                <Help toggleHelp={toggleHelp} showHelp={showHelp}>
                    <Switch>
                        <Route path='/eq' render={props => <HelpEquation {...props} toggleHelp={toggleHelp} />} />
                        <Route path='/sheet' render={props => <HelpSheet {...props} toggleHelp={toggleHelp} />} />
                    </Switch>
                </Help>
                {/* <Footer /> */}
            </BrowserRouter>
        </StyledApp>
    )
}
