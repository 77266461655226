import React from 'react'
import styled from 'styled-components'

const StyledHelp = styled.div`
    position: fixed;
    height: calc(100% - 64px);
    width: 100%;
    right: 0px;
    top: 64px;
    transition: top 200ms ease-in-out;
    transition: height 200ms ease-in-out;
    box-shadow: 0px -4px 8px #888;
`

export default function Help (props) {
    const { children, showHelp } = props
    if (!showHelp) {
        return null
    }
    return <StyledHelp>
        {children}
    </StyledHelp>
}
