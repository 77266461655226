export function objectToHistory (obj: Object) {
    const res = {}
    if (typeof obj !== 'object') return obj
    let hasProps = false
    Object.keys(obj).forEach(prop => {
        if (obj[prop] && (obj[prop].text || obj[prop].context)) {
            res[prop] = {}
            res[prop].t = obj[prop].text
            res[prop].c = objectToHistory(obj[prop].context)
            if (res[prop].c || res[prop].t) {
                hasProps = true
            }
        }
    })
    if (!hasProps) return undefined
    return res
}

export function objectFromHistory (obj: Object) {
    const res = {}
    if (typeof obj !== 'object') return obj
    Object.keys(obj).forEach(prop => {
        res[prop] = {}
        res[prop].text = obj[prop].t
        res[prop].context = objectFromHistory(obj[prop].c)
    })
    return res
}

export function urlToObject (url: String) {
    return JSON.parse(decodeURI(url.replace(/^(\/eq)*\?/, '')).replace(/["~!@#$%&=]/g, '').replace(/`/g, '"'))
}

export function getEquationFromUrl (url: String) {
    if (!url) {
        return {}
    }
    const urlObject = urlToObject(url)
    return {
        text: urlObject.t || '',
        context: objectFromHistory(urlObject.c || {})
    }
}

export function columnSettingsToHistory (columnSettings) {
    const newColumnSettings = {}
    let somethingWasAdded = false
    Object.keys(columnSettings).forEach(column => {
        const newSetting = {}
        let shouldAdd = false
        if (columnSettings[column].start) {
            shouldAdd = true
            newSetting.s = columnSettings[column].start
        }
        if (columnSettings[column].interval) {
            shouldAdd = true
            newSetting.i = columnSettings[column].interval
        }
        if (shouldAdd) {
            somethingWasAdded = true
            newColumnSettings[column] = newSetting
        }
    })
    if (somethingWasAdded) {
        return newColumnSettings
    } else {
        return undefined
    }
}

export function columnSettingsFromHistory (columnSettings) {
    const newColumnSettings = {}
    Object.keys(columnSettings).forEach(column => {
        const newSetting = {}
        if (columnSettings[column].s) {
            newSetting.start = columnSettings[column].s
        }
        if (columnSettings[column].i) {
            newSetting.interval = columnSettings[column].i
        }
        newColumnSettings[column] = newSetting
    })
    return newColumnSettings
}

export function getFromHistory (history: Object) {
    const urlObject = history.location.search.length > 1 ? urlToObject(history.location.search) : {}
    const generatorSettings = {}
    if (urlObject.l) {
        generatorSettings.left = urlObject.l
    }
    if (urlObject.r) {
        generatorSettings.right = urlObject.r
    }
    return {
        text: urlObject.t || '',
        context: objectFromHistory(urlObject.c || {}),
        generatorSettings,
        columnSettings: columnSettingsFromHistory(urlObject.s || {})
    }
}

export function passToHistory ({ history, text, context, generatorSettings, columnSettings, pushToHistory = false }: { history: any, text: string, context: Object }) {
    if (!text && !context) return history.replace()
    const newContext = {}
    if (text) {
        newContext.t = text
    }
    if (context) {
        newContext.c = objectToHistory(context)
    }
    if (generatorSettings) {
        if (generatorSettings.left) {
            if (parseInt(generatorSettings.left, 10) > 1000) {
                newContext.l = 1000
            } else {
                newContext.l = generatorSettings.left
            }
        }
        if (generatorSettings.right) {
            if (parseInt(generatorSettings.right, 10) > 1000) {
                newContext.r = 1000
            } else {
                newContext.r = generatorSettings.right
            }
        }
        if (columnSettings) {
            const newColumnSettings = columnSettingsToHistory(columnSettings)
            if (newColumnSettings) {
                newContext.s = newColumnSettings
            }
        }
    }
    const newContextString = JSON.stringify(newContext).replace(/[`~!@#$%&=_]/g, '').replace(/"/g, '`')
    if (newContextString !== '{}' && newContextString.length > 0) {
        if (pushToHistory) {
            return history.push('?' + newContextString)
        } else {
            return history.replace('?' + newContextString)
        }
    } else {
        if (pushToHistory) {
            return history.push()
        } else {
            return history.replace()
        }
    }
}
