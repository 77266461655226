import getColor from './getColor'
import ignoreList from './ignoreList'

export function mathmlWrapper ({ title, text }: { title: string, text: string }): string {
    return `<math title="${title}">${text.replace(/_/g, '')}</math>`
}

export function colorVariable ({ text, variable }: { text: string, variable: string }): string {
    let res = text
    const varText = variable
    const shouldSub = variableSubscript({ variable })
    if (shouldSub.subscript) {
        res = res.replace(findVarRegex({ variable }), `$1<msub><mi mathcolor="${getColor(variable)}">${shouldSub.variable}_</mi><mn mathcolor="${getColor(variable)}">${shouldSub.subscript}</mn></msub>$3`)
    } else {
        res = res.replace(findVarRegex({ variable }), `$1<mrow><mi mathcolor="${getColor(variable)}">${varText}</mi></mrow>$3`)
    }
    return res
}

export function variableSubscript ({ variable }: { variable: string}) {
    const subMatch = variable.match(/([0-9]+)$/)
    if (subMatch) {
        return {
            variable: variable.slice(0, variable.length - subMatch[1].length),
            subscript: subMatch[1]
        }
    }
    return { variable }
}

export function findVarRegex ({ variable }: { variable: string }) {
    return new RegExp(`(^|[^A-Za-z0-9 _])(${removeSpace({ text: variable })})([^A-Za-z0-9 _]|$)`, 'g')
}

export function expandVariableWithColor ({ text, variable, expanded }: {text: string, variable: string, expanded: string}): string {
    let varText = variable
    const shouldSub = variableSubscript({ variable })
    if (shouldSub.subscript) {
        varText = `<msub><mi>${shouldSub.variable}_</mi><mn>${shouldSub.subscript}</mn></msub>`
    }
    return text.replace(findVarRegex({ variable }), '$1' + mathmlTable({
        color: getColor(variable),
        rows: [
            [varText],
            [`<mo>(</mo>${expanded}<mo>)</mo>`]
        ]
    }) + '$3')
}

export function mathmlTable ({ color, rows }: { color: string, rows: Array<Array<string>>}): string {
    return `<mtable mathcolor="${color}">${rows.reduce((acc, row) => `${acc}<mtr>${row.reduce((cellAcc, cell) => {
        return `${cellAcc}<mtd><mrow>${addMiMaybe({ text: cell })}</mrow></mtd>`
    }, '')}</mtr>`, '')}</mtable>`
}

export function addMiMaybe ({ text }: { text: string }): string {
    if (!/^<.*>$/.test(text)) {
        return `<mi>${text}</mi>`
    } else {
        return text
    }
}

export function extrapolateMathml ({ text }: { text: string }): string {
    let res = text
    res = padNumbers({ text: res })
    res = removeSpace({ text: res })
    res = padOperators({ text: res })
    res = padSpecial({ text: res })
    res = beautifyGlobal({ text: res, global: 'sqrt', rightTag: 'msqrt' })
    res = beautifyGlobal({
        text: res,
        global: '<mo>&divide;</mo>',
        cutoffChars: ['+', '-', ')', '*'],
        cutoffCharsReverse: ['+', '-', '*', '/', '('],
        masterOpen: '<mstyle displaystyle="true"><mfrac>',
        masterClose: '</mfrac></mstyle>',
        leftTag: 'mrow',
        rightTag: 'mrow'
    })
    res = padParentheses({ text: res })
    res = padIgnoreList({ text: res })
    return res
}

export function padNumbers ({ text }: { text: string }): string {
    let res = text
    res = res.replace(/(^|[^A-Za-z0-9 ])([0-9]+)/g, '$1<mo>$2</mo>')
    return res
}

export function removeSpace ({ text }: { text: string }): string {
    let res = text
    res = res.replace(/(^|[^A-Za-z0-9])( +)($|[^A-Za-z0-9])/g, '$1$3')
    res = res.replace(/(^|[A-Za-z0-9])( +)($|[^A-Za-z0-9])/g, '$1$3')
    res = res.replace(/(^|[^A-Za-z0-9])( +)($|[A-Za-z0-9])/g, '$1$3')
    res = res.replace(/(^|[^A-Za-z0-9])( +)($|[^A-Za-z0-9])/g, '$1$3')
    return res
}

export function padOperators ({ text }: { text: string}): string {
    const operators = ['\\+', '-', '/', '\\*']
    const operatorValue = {
        '\\+': '&plus;',
        '-': '&minus;',
        '/': '&divide;',
        '\\*': '&times;'
    }
    return operators.reduce((acc, operator) => {
        let response = acc
        response = response.replace(new RegExp(`([^</*-+])${operator}`, 'g'), `$1<mo>${operatorValue[operator]}</mo>`) // replace
        return response
    }, text)
}

export function padSpecial ({ text }: { text: string }): string {
    let res = text
    res = res.replace(/([^A-Za-z0-9<>/;#"'= &()])/g, '<mo>$1</mo>') // replace the rest of the unknown elements
    return res
}

/*
    global: the text to search for
    rightTag: the tag to surround the extent of parentheses to the right of the global
    leftTag: the tag to surround the extent of parentheses to the left of the global
*/
export function beautifyGlobal ({
    text,
    global,
    cutoffChars = [],
    cutoffCharsReverse = [],
    masterOpen = '',
    masterClose = '',
    rightTag = '',
    leftTag = ''
}: {
    text: string,
    global: string,
    cutoffChars?: Array<string>,
    cutoffCharsReverse?: Array<string>,
    masterOpen?: string,
    masterClose?: string,
    rightTag?: string,
    leftTag?: string
}): string {
    let res = text
    const hasLeftTag = leftTag.length > 0
    const hasRightTag = rightTag.length > 0
    res = protectConverted({ text: res })
    let segments = splitGlobal({ text: res, global })
    while (segments.length > 1) {
        let endAllLeft
        let endAllRight
        const extentLeft = getParenthesesExtent({ text: segments[0], cutoffChars: cutoffCharsReverse, reverse: true })
        const extentRight = getParenthesesExtent({ text: segments[1], cutoffChars })
        const { left, right } = stripExtraParentheses({ left: extentLeft, right: extentRight })
        const semiTrimmedLeft = trimParentheses({ text: left })
        const semiTrimmedRight = trimParentheses({ text: right })
        endAllLeft = trimOpenParentheses({ left: semiTrimmedLeft, right: semiTrimmedRight })
        endAllRight = trimCloseParentheses({ left: semiTrimmedLeft, right: semiTrimmedRight })

        const nestedLeftSplit = splitGlobal({ text: endAllLeft, global })
        const nestedRightSplit = splitGlobal({ text: endAllRight, global })
        if (nestedLeftSplit.length > 1) {
            endAllLeft = beautifyGlobal({ text: endAllLeft, global, cutoffChars, cutoffCharsReverse, masterOpen, masterClose, rightTag, leftTag })
        }
        if (nestedRightSplit.length > 1) {
            endAllRight = beautifyGlobal({ text: endAllRight, global, cutoffChars, cutoffCharsReverse, masterOpen, masterClose, rightTag, leftTag })
        }

        const mathml = `${masterOpen}${hasLeftTag ? `<${leftTag}>${endAllLeft}</${leftTag}>` : endAllLeft}${hasRightTag ? `<${rightTag}>${endAllRight}</${rightTag}>` : endAllRight}${masterClose}`
        res = res.replace(left + global + right, mathml)
        res = protectConverted({ text: res })
        segments = splitGlobal({ text: res, global })
    }
    res = revealConverted({ text: res })
    return res
}

export function protectConverted ({ text }: { text: string }): string {
    let res = text
    specials.forEach((special, index) => {
        res = res.replace(new RegExp(special, 'g'), `_${index}_`)
    })
    return res
}

const specials = [
    'msqrt'
]

export function splitGlobal ({ text, global }: { text: string, global: string }) {
    const regex = new RegExp(`(.*)${global}(.*)`)
    const match = text.match(regex)
    if (match && match.length > 1) {
        return [match[1], match[2]]
    }
    return []
}

export function getParenthesesExtent (
    { text, open = 0, reverse = false, cutoffChars = [] }:
    { text: string, open?: number, reverse?: boolean, cutoffChars?: Array<string> }
): string {
    if (!text) return ''
    if (!reverse) {
        const match = text.match(/^([^()]*)([()])/)
        if (!match || match.length === 0) {
            if (shouldCutoff({ text, open, cutoffChars })) {
                return cutoff({ text, cutoffChars, reverse })
            }
            return text
        }

        const firstMatch = match[0]
        const textMatch = match[1]
        const parenthesesMatch = match[2]

        if (shouldCutoff({ text: textMatch, open, cutoffChars })) {
            return cutoff({ text: textMatch, cutoffChars, reverse })
        }
        const slice = text.slice(firstMatch.length)
        if (parenthesesMatch === '(') {
            return firstMatch + getParenthesesExtent({ text: slice, open: open + 1, reverse, cutoffChars })
        } else {
            if (open <= 1) {
                return firstMatch
            }
            return firstMatch + getParenthesesExtent({ text: slice, open: open - 1, reverse, cutoffChars })
        }
    } else {
        const match = text.match(/([()])([^()]*)$/)
        if (!match || match.length === 0) {
            if (shouldCutoff({ text, open, cutoffChars })) {
                return cutoff({ text, cutoffChars, reverse })
            }
            return text
        }

        const firstMatch = match[0]
        const textMatch = match[2]
        const parenthesesMatch = match[1]

        if (shouldCutoff({ text: textMatch, open, cutoffChars })) {
            return cutoff({ text: textMatch, cutoffChars, reverse })
        }
        const slice = text.slice(0, text.length - firstMatch.length)
        if (parenthesesMatch === ')') {
            return getParenthesesExtent({ text: slice, open: open + 1, reverse, cutoffChars }) + firstMatch
        } else {
            if (open <= 1) {
                return firstMatch
            }
            return getParenthesesExtent({ text: slice, open: open - 1, reverse, cutoffChars }) + firstMatch
        }
    }
}

export function shouldCutoff ({ text, cutoffChars, open }: { text: string, cutoffChars: Array<string>, open: number}): boolean {
    const filteredCutoffChars = cutoffChars.filter(filterCutoffChars)
    if (open === 0 && cutoffChars.includes('/')) {
        if (/<\/mfrac><\/mstyle>/.test(text) || /<mo>&divide;<\/mo>/.test(text)) {
            return true
        }
    }
    if (open === 0 && cutoffChars.includes('*')) {
        if (/<mo>&times;<\/mo>/.test(text)) {
            return true
        }
    }
    if (open === 0 && filteredCutoffChars.length > 0 && new RegExp(`[\\${filteredCutoffChars.join('\\')}]`, 'g').test(maskCutoffWhiteList({ text }))) {
        return true
    }
    return false
}

export function maskCutoffWhiteList ({ text }: { text: string }): string {
    let res = text
    Object.keys(cutoffWhiteList).forEach(key => {
        res = res.replace(new RegExp(key, 'g'), cutoffWhiteList[key])
    })
    return res
}

const cutoffWhiteList = {
    '<mo>&plus;</mo>': '+',
    '<mo>&minus;</mo>': '-',
    '<mo>&times;</mo>': '*'
}

export function filterCutoffChars (cutoffChar: string) {
    return whiteListCutoffChars.includes(cutoffChar)
}

export const whiteListCutoffChars = [
    '+',
    '-',
    '*',
    '(',
    ')'
]

export function cutoff ({ text, cutoffChars, reverse }: { text: string, cutoffChars: Array<string>, reverse?: boolean}) {
    const filteredCutoffChars = cutoffChars.filter(filterCutoffChars)
    const maskedText = maskCutoffWhiteList({ text })
    let remainingRegex
    let divideCutoffRegex
    let alternateDivideCutoffRegex
    if (reverse) {
        remainingRegex = new RegExp(`[\\${filteredCutoffChars.join('\\')}]([^\\${filteredCutoffChars.join('\\')}]+)$`)
        divideCutoffRegex = new RegExp(`<mo>&divide;</mo>([^\\${filteredCutoffChars.join('\\')}]+)$`)
        alternateDivideCutoffRegex = new RegExp(`</mfrac></mstyle>([^\\${filteredCutoffChars.join('\\')}]+)$`)
    } else {
        remainingRegex = new RegExp(`^([^\\${filteredCutoffChars.join('\\')}]+)[\\${filteredCutoffChars.join('\\')}]`)
        divideCutoffRegex = new RegExp(`^([^\\${filteredCutoffChars.join('\\')}]+)<mo>&divide;</mo>`)
        alternateDivideCutoffRegex = new RegExp(`^([^\\${filteredCutoffChars.join('\\')}]+)<mstyle displaystyle="true"><mfrac>`)
    }

    const cutoffMatch = maskedText.match(remainingRegex)
    const cutoffDivideMatch = maskedText.match(divideCutoffRegex)
    const cutoffAlternateDivideMatch = maskedText.match(alternateDivideCutoffRegex)

    let res = ''
    if (cutoffMatch) {
        res = cutoffMatch[1]
    }
    if (cutoffDivideMatch) {
        // Should only enter here if we're dealing with the preceding part of a divide
        if (!res || cutoffDivideMatch[1].length < res.length) {
            res = cutoffDivideMatch[1]
        }
    }
    if (cutoffAlternateDivideMatch) {
        // Should only enter here if we're dealing with the preceding part of a divide
        if (!res || cutoffAlternateDivideMatch[1].length < res.length) {
            res = cutoffAlternateDivideMatch[1]
        }
    }
    return unmaskCutoffWhiteList({ text: res })
}

export function unmaskCutoffWhiteList ({ text }: { text: string }): string {
    let res = text
    Object.keys(cutoffWhiteList).forEach(key => {
        res = res.replace(new RegExp('\\' + cutoffWhiteList[key], 'g'), key)
    })
    return res
}

export function stripExtraParentheses ({ left, right }: { left: string, right: string }) {
    // Remove unmatched preceding or trailing parentheses
    const leftHasPreceding = /^\(/.test(left)
    const rightHasPreceding = /^\(/.test(right)
    const leftHasTrailing = /\)$/.test(left)
    const rightHasTrailing = /\)$/.test(right)
    if (leftHasPreceding && !leftHasTrailing && !rightHasPreceding && !rightHasTrailing) {
        return { left: left.replace(/^\(/, ''), right }
    }
    if (!leftHasPreceding && !leftHasTrailing && !rightHasPreceding && rightHasTrailing) {
        return { left, right: right.replace(/\)$/, '') }
    }
    return { left, right }
}

export function trimParentheses ({ text }: { text: string }): string {
    return text.replace(/^\((.*)\)$/g, '$1')
}

export function trimOpenParentheses ({ left, right }: { left: string, right: string }): string {
    if (/\)$/.test(right)) {
        return left.replace(/^\(/g, '')
    }
    return left
}

export function trimCloseParentheses ({ left, right }: { left: string, right: string }): string {
    if (/^\(/.test(left)) {
        return right.replace(/\)$/g, '')
    }
    return right
}

export function padParentheses ({ text }: { text: string }): string {
    let res = text
    res = res.replace(/^(.{0,3})([()])/g, '$1<mo>$2</mo>') // replace at the beginning
    res = res.replace(/([()])([^()]{0,4})$/g, '<mo>$1</mo>$2') // replace at the end
    res = res.replace(/([()])(?!<\/mo>)/g, '<mo>$1</mo>')
    return res
}

export function revealConverted ({ text }: { text: string }): string {
    let res = text
    specials.forEach((special, index) => {
        res = res.replace(new RegExp(`_${index}_`, 'g'), special)
    })
    return res
}

export function padIgnoreList ({ text }: { text: string }): string {
    let res = text
    ignoreList.forEach(item => {
        res = res.replace(new RegExp(`(^|[^A-Za-z0-9])(${item})($|[^A-Za-z0-9])`, 'g'), '$1<mo>$2</mo>$3')
    })
    return res
}
