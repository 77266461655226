import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledAutocomplete = styled.div`
    position: relative;
    width: 100%;
`

const StyledInput = styled.input`
    position: relative;
    width: 100%;
`

const Suggestions = styled.div`
    position: absolute;
    width: 100%;
`

const SuggestionsWithHover = styled(Suggestions)`
    div:hover {
        background: lightblue;
    }
`

const StyledSuggestion = styled.div`
    cursor: pointer;
    background: lightgray;
    padding: 4px;
`

function Suggestion (props) {
    return (
        <StyledSuggestion {...props} style={{ backgroundColor: props.active && 'lightblue' }}>
            {props.id}
        </StyledSuggestion>
    )
}

function getSuggestions (options, textToMatch) {
    const regex = new RegExp(textToMatch, 'i')
    return options.filter(option => regex.test(option.id))
}

function handleKeyDown (event, state, setState, suggestions, onSelect) {
    if (event.key === 'ArrowDown') {
        if (state.activeIndex === undefined || state.activeIndex + 1 >= suggestions.length) {
            setState({ isMouseControlled: false, activeIndex: 0 })
        } else {
            setState({ isMouseControlled: false, activeIndex: state.activeIndex + 1 })
        }
    }
    if (event.key === 'ArrowUp') {
        if (state.activeIndex === undefined) {
            setState({ isMouseControlled: false, activeIndex: 0 })
        } else if (state.activeIndex === 0) {
            setState({ isMouseControlled: false, activeIndex: suggestions.length - 1 })
        } else {
            setState({ isMouseControlled: false, activeIndex: state.activeIndex - 1 })
        }
    }
    if (event.key === 'Enter') {
        if (!state.isMouseControlled && state.activeIndex !== undefined) {
            event.stopPropagation()
            onSelect(suggestions[state.activeIndex].id)
        } else if (suggestions.length === 1) {
            event.stopPropagation()
            onSelect(suggestions[0].id)
        }
    }
    if (event.key === 'Escape') {
        if (!state.isMouseControlled || state.activeIndex !== undefined) {
            setState({ isMouseControlled: true })
        }
    }
}

export default function Autocomplete (props) {
    const { className, editorRef, value, onChange, onSelect, options } = props

    const [suggestions, setSuggestions] = useState([])

    useEffect(() => {
        setSuggestions(getSuggestions(options, value))
    }, [value, options])

    const [state, setState] = useState({ isMouseControlled: true })

    return (
        <StyledAutocomplete className={className} onMouseMove={() => {
            if (state.isMouseControlled === false) {
                setState({ isMouseControlled: true, activeIndex: state.activeIndex })
            }
        }}>
            <StyledInput
                value={value}
                onChange={event => onChange(event.target.value)}
                onKeyDown={event => handleKeyDown(event, state, setState, suggestions, onSelect)}
                ref={editorRef}
            />
            {state.isMouseControlled
                ? <SuggestionsWithHover>
                    {suggestions.map(option => <Suggestion key={option.id} onClick={() => onSelect(option.id)} {...option}></Suggestion>)}
                </SuggestionsWithHover>
                : <Suggestions>
                    {suggestions.map((option, index) => <Suggestion key={option.id} active={index === state.activeIndex} onClick={() => onSelect(option.id)} {...option}></Suggestion>)}
                </Suggestions>
            }
        </StyledAutocomplete>
    )
}
