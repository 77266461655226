import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import crown from 'icons/crown.svg'

const StyledCommandButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
`

const StyledCommandButtonWithHover = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Command', -20)}
`

export default function CommandButton (props) {
    if (props.hover) {
        return (
            <StyledCommandButtonWithHover {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={crown} alt='Command' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledCommandButtonWithHover>
        )
    } else {
        return (
            <StyledCommandButton {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={crown} alt='Command' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledCommandButton>
        )
    }
}
