import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import * as styles from 'styles'

const StyledHeader = styled.div`
    background-color: ${styles.extraLightGray};
    height: 62px;
    min-height: 62px;
    width: 100%;
    display: flex;
    flex-grow: 0 !important;
    justify-content: space-between;
    ${'' /* box-shadow: 0px 4px 4px #888;
    border-radius: 6px; */}
`

const Image = styled.img`
    margin-left: 2px;
    height: 60px;
`

const AfterImage = styled.div`
    padding-left: 10px;
`

const TitleLink = styled.div`
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
`

const LinkHome = styled.div`
    cursor: pointer;
    display: flex;
`

const ImageWrapper = styled.div`
    color: black;
    padding-left: 10px;
`

function Header (props) {
    const { history } = props
    return (
        <StyledHeader>
            <LinkHome onClick={() => history.push('/')}>
                <ImageWrapper>
                    <Image src='favicon.ico' alt='' />
                </ImageWrapper>
                <AfterImage>
                    <TitleLink>Mathite</TitleLink>
                </AfterImage>
            </LinkHome>
            {props.children}
        </StyledHeader>
    )
}

export default withRouter(Header)
