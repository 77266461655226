import React, { useEffect, useState, useRef } from 'react'

export default function Draw (props: {
    imageDataUrl: Object,
    setContext: Function,
    style: Object,
    height: number,
    width: number,
    penColor: string,
    penWidth: string,
    penType: string
}) {
    const {
        imageDataUrl,
        setContext,
        style,
        width,
        height,
        penColor,
        penWidth,
        penType
    } = props
    const [positionX, setPositionX] = useState()
    const [positionY, setPositionY] = useState()
    const [mouseDown, setMouseDown] = useState()

    const sketchElement = useRef()

    useEffect(() => {
        const context = sketchElement.current.getContext('2d')
        if (imageDataUrl) {
            const img = new Image()
            img.src = imageDataUrl
            img.onload = () => {
                context.drawImage(img, 0, 0)
            }
        }
        setContext(context)
    }, [imageDataUrl, setContext])

    function drawMouseDown (event) {
        setPositionAndDraw(event, false)
        setMouseDown(true)
    }

    function drawMouseMove (event) {
        if (mouseDown) {
            setPositionAndDraw(event)
        }
    }

    function drawMouseUp (event) {
        setPositionAndDraw(event)
        setMouseDown(false)
    }

    function drawMouseLeave () {
        setMouseDown(false)
    }

    function drawWithState (canvas, x, y) {
        drawLine(canvas, positionX, positionY, x, y)
        setPositionX(x)
        setPositionY(y)
    }

    function drawLine (canvas, startX, startY, endX, endY) {
        const context = canvas.getContext('2d')
        context.beginPath()
        context.moveTo(startX, startY)
        context.lineTo(endX, endY)
        context.strokeStyle = penColor
        context.lineWidth = penWidth
        context.lineCap = penType
        context.stroke()
    }

    function setPositionAndDraw (event, draw = true) {
        const boundingRect = sketchElement.current.getBoundingClientRect()
        if (event.changedTouches) {
            Object.values(event.changedTouches).forEach(touch => {
                const x = touch.clientX - boundingRect.left
                const y = touch.clientY - boundingRect.top
                if (draw) {
                    drawWithState(sketchElement.current, x, y)
                } else {
                    setPositionX(x)
                    setPositionY(y)
                    sketchElement.current.focus()
                    sketchElement.current.blur()
                }
            })
        } else {
            const x = event.pageX - boundingRect.left - window.pageXOffset
            const y = event.pageY - boundingRect.top - window.pageYOffset
            if (draw) {
                drawWithState(sketchElement.current, x, y)
            } else {
                setPositionX(x)
                setPositionY(y)
            }
        }
        const context = sketchElement.current.getContext('2d')
        setContext(context)
    }

    return (
        <canvas ref={sketchElement}
            style={Object.assign({}, { touchAction: 'none', outline: 'none' }, style)}
            width={width}
            height={height}
            tabIndex={0}
            onMouseMove={drawMouseMove}
            onMouseDown={drawMouseDown}
            onMouseUp={drawMouseUp}
            onMouseLeave={drawMouseLeave}
            onTouchStart={event => setPositionAndDraw(event, false)}
            onTouchMove={setPositionAndDraw}
            onTouchEnd={setPositionAndDraw}>
        </canvas>
    )
}
