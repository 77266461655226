import * as React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { passToHistory } from 'utils/qs'
import getColor from 'utils/math/getColor'
import { formatNumber, recursiveSolve, solveWithAddedContext } from 'utils/math'
import premade from 'utils/premade.json'
import { getLocalStorage, setLocalStorage } from 'utils/localStorage'
import { withQsContext } from 'containers/qsContext'
import MathML from 'components/MathML'
import ObjectCreator from 'components/ObjectCreator'
import TableGenerator from 'components/TableGenerator'
import RightFloater from 'components/RightFloater'
import SearchButton from 'components/buttons/Search'
import SaveButton from 'components/buttons/Save'
import MaterialButton from 'components/buttons/Material'
import ForgeButton from 'components/buttons/Forge'
import CommandButton from 'components/buttons/Command'
import ReviewButton from 'components/buttons/Review'
import DataGrid from 'components/DataGrid'
import Graph from 'components/Graph'
import FunctionSearch from 'components/FunctionSearch'
import Accordion from 'components/Accordion'
import * as styles from 'styles'

const StyledRoot = styled.div`
    display: flex;
    min-height: 1px;
`

const StyledMain = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 800px) {
      width: 100% !important;
    }
    display: flex;
    overflow: auto;
`

const StyledSearch = styled.div`
    position: fixed;
    width: 0px;
    right: 0px;
    top: 64px;
    bottom: 0px;
    transition: width 150ms ease-in-out;
`

const AccordionContents = styled.div`
    padding: 5px 60px;
    @media (max-width: 800px) {
      padding-left: 5px;
      padding-right: 5px;
    }
`

const StyledMaterialSection = styled.div`
`

const StyledObjectCreator = styled.div`
`

const StyledMath = styled.div`
`

const StyledGraph = styled.div`
`

const StyledTableScrollableLimit = styled.div`
    width: 100%;
    overflow: auto;
`

const StyledTableScrollable = styled.div`
`

const StyledDataGrid = styled.div`
    input {
      background-color: ${styles.yellow};
      ${styles.inputStyle}
    }
`

const Section = styled.div`
    display: flex;
    align-items: center;
`

const Title = styled.div`
    font-size: 1.5rem;
    padding-left: 1rem;
    color: ${styles.primaryColor};
    font-weight: bold;
`
// text-shadow: -1px 1px 2px #000;

const StyledSolution = styled.div`
    font-weight: bold;
    text-align: center;
    padding: 20px;
`

const MenuButton = styled.div`
    padding: 5px;
`

const StyledText = styled.div`
    padding: 5px;
`

class Root extends React.Component {
    // interface State {
    //   table: Array<Array<any>>,
    //   showSearch: boolean
    // }
    state = {
        table: [
            []
        ],
        initialSelectionCell: {}
    }

    UNSAFE_componentWillMount () {
        const localStorageFunctions = this.getLocalStorageFunctions()
        const accordionState = {
            showSearch: true,
            showMaterial: true,
            showForge: true,
            showReview: true,
            showCommand: true,
            ...this.getLocalStorageAccordionState()
        }
        let category = ''
        if (this.props.text) {
            if (localStorageFunctions[this.props.text]) {
                category = localStorageFunctions[this.props.text].category
            } else if (premade[this.props.text]) {
                category = premade[this.props.text].category
            }
        }
        let notes = ''
        if (this.props.text) {
            if (localStorageFunctions[this.props.text]) {
                notes = localStorageFunctions[this.props.text].notes
            } else if (premade[this.props.text]) {
                notes = premade[this.props.text].notes
            }
        }
        this.setState({
            table: this.generateTable(this.props),
            localStorageFunctions,
            accordionState,
            notes,
            category
        })
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        const localStorageFunctions = this.getLocalStorageFunctions()
        const { info = {}, generatorSettings = {}, columnSettings = {} } = nextProps
        const missingVariables = info.fullMissingContext || []
        const currentProps = this.props
        const currentMissingVariables = currentProps.info.missingVariables || []
        if (currentProps.info.solvableEquation !== info.solvableEquation ||
            currentProps.generatorSettings.left !== generatorSettings.left ||
            currentProps.generatorSettings.right !== generatorSettings.right ||
            Object.keys(currentProps.columnSettings).some(key => {
                return typeof currentProps.columnSettings[key] !== typeof columnSettings[key] ||
                    currentProps.columnSettings[key].start !== columnSettings[key].start ||
                    currentProps.columnSettings[key].interval !== columnSettings[key].interval
            }) ||
            currentMissingVariables.some(previous => !missingVariables.includes(previous)) ||
            missingVariables.some(next => !currentMissingVariables.includes(next))) {
            const newState = {
                table: this.generateTable(nextProps),
                localStorageFunctions
            }
            if (this.props.text !== nextProps.text) {
                if (!nextProps.text) {
                    newState.category = ''
                } else if (localStorageFunctions[nextProps.text]) {
                    newState.category = localStorageFunctions[nextProps.text].category || ''
                } else if (premade[nextProps.text]) {
                    newState.category = premade[nextProps.text].category || ''
                }
            }
            if (this.props.text !== nextProps.text) {
                if (!nextProps.text) {
                    newState.notes = ''
                } else if (localStorageFunctions[nextProps.text]) {
                    newState.notes = localStorageFunctions[nextProps.text].notes || ''
                } else if (premade[nextProps.text]) {
                    newState.notes = premade[nextProps.text].notes || ''
                }
            }
            this.setState(newState)
        }
    }

    generateTable ({ info, columnSettings, generatorSettings }) {
        const missingVariables = info.fullMissingContext || []
        const { left = 0, right = 10 } = generatorSettings
        const table = []
        const generatorColumns = missingVariables.map(column => {
            let start = 0
            let interval = 1
            if (columnSettings[column] && columnSettings[column].start) {
                start = columnSettings[column].start
            }
            if (columnSettings[column] && columnSettings[column].interval) {
                interval = columnSettings[column].interval
            }
            return {
                name: column,
                start,
                interval
            }
        })
        const runner = {
            iteration: -1 * parseInt(left, 10)
        }
        function calculateIteration (column) {
            return parseFloat(column.start) + runner.iteration * parseFloat(column.interval)
        }
        while (runner.iteration <= parseInt(right, 10)) {
            const simpleContext = {}
            const cells = generatorColumns.map(column => {
                const res = calculateIteration(column)
                simpleContext[column.name] = res
                return Number(res.toFixed(10))
            })
            const solution = Number(parseFloat(solveWithAddedContext({ text: info.solvableEquation, simpleContext }).solution || 0).toFixed(10))
            table.push([...cells, solution])
            runner.iteration++
        }
        return table
    }

    passCleanedSettingsToHistory (oldHistoryObject, newHistoryObject) {
        const cleanedHistoryObject = {
            history: oldHistoryObject.history,
            text: oldHistoryObject.text,
            category: oldHistoryObject.category,
            notes: oldHistoryObject.notes,
            context: { ...oldHistoryObject.context },
            columnSettings: { ...oldHistoryObject.columnSettings },
            generatorSettings: { ...oldHistoryObject.generatorSettings },
            pushToHistory: (!oldHistoryObject.text && newHistoryObject.text) || (oldHistoryObject.text && !newHistoryObject.text)
        }

        if (newHistoryObject.columnSettings) {
            cleanedHistoryObject.columnSettings = { ...newHistoryObject.columnSettings }
            cleanedHistoryObject.pushToHistory = false
        }

        if (newHistoryObject.generatorSettings) {
            cleanedHistoryObject.generatorSettings = { ...newHistoryObject.generatorSettings }
            cleanedHistoryObject.pushToHistory = false
        }

        if (typeof newHistoryObject.category === 'string') {
            cleanedHistoryObject.category = newHistoryObject.category
            cleanedHistoryObject.pushToHistory = false
        }

        if (typeof newHistoryObject.notes === 'string') {
            cleanedHistoryObject.notes = newHistoryObject.notes
            cleanedHistoryObject.pushToHistory = false
        }

        if (newHistoryObject.text || newHistoryObject.context) {
            const info = recursiveSolve({ text: newHistoryObject.text, context: newHistoryObject.context })
            Object.keys(cleanedHistoryObject.columnSettings).forEach(key => {
                if (!info.fullMissingContext.includes(key)) {
                    delete cleanedHistoryObject.columnSettings[key]
                }
            })
            cleanedHistoryObject.text = newHistoryObject.text
            cleanedHistoryObject.context = { ...newHistoryObject.context }
        }

        passToHistory({ ...cleanedHistoryObject })
    }

    colorVariables ({ variables, columnColorObject }) {
        return variables.map(variable => {
            return <div key={variable} style={{ fontWeight: 'bold', color: columnColorObject[variable] }}>{variable}</div>
        })
    }

    toggleHelp = () => {
        this.setState({ showHelp: !this.state.showHelp })
    }

    toggleSearch = () => {
        this.setLocalStorageAccordionState({ ...this.state.accordionState, showSearch: !this.state.accordionState.showSearch })
    }

    toggleMaterial = () => {
        this.setLocalStorageAccordionState({ ...this.state.accordionState, showMaterial: !this.state.accordionState.showMaterial })
    }

    toggleForge = () => {
        this.setLocalStorageAccordionState({ ...this.state.accordionState, showForge: !this.state.accordionState.showForge })
    }

    toggleReview = () => {
        this.setLocalStorageAccordionState({ ...this.state.accordionState, showReview: !this.state.accordionState.showReview })
    }

    toggleWield = () => {
        this.setLocalStorageAccordionState({ ...this.state.accordionState, showWield: !this.state.accordionState.showWield })
    }

    toggleCommand = () => {
        this.setLocalStorageAccordionState({ ...this.state.accordionState, showCommand: !this.state.accordionState.showCommand })
    }

    getLocalStorageFunctions = () => {
        return getLocalStorage('localFunctions') || {}
    }

    setLocalStorageFunctions = (localStorageFunctions) => {
        setLocalStorage('localFunctions', localStorageFunctions)
        this.setState({ localStorageFunctions })
    }

    getLocalStorageAccordionState = () => {
        return getLocalStorage('accordion')
    }

    setLocalStorageAccordionState = (accordionState) => {
        setLocalStorage('accordion', accordionState)
        this.setState({ accordionState })
    }

    saveLocalFunction = ({ history, name, category, notes }) => {
        const localStorageFunctions = this.getLocalStorageFunctions()
        localStorageFunctions[name] = {
            category,
            notes,
            date: Date.now(),
            url: history.location.path || '' + history.location.search || ''
        }
        this.setLocalStorageFunctions(localStorageFunctions)
    }

    deleteLocalFunction = (name) => {
        const localStorageFunctions = this.getLocalStorageFunctions()
        delete localStorageFunctions[name]
        this.setLocalStorageFunctions(localStorageFunctions)
    }

    setInitialSelectionCell = (selection) => {
        this.setState({ ...this.state, initialSelectionCell: selection })
    }

    render () {
        const { info, text, context, columnSettings, generatorSettings, history } = this.props
        const historyObject = { history, text, context, generatorSettings, columnSettings }

        const {
            table,
            category,
            notes,
            localStorageFunctions,
            accordionState: {
                showSearch,
                showMaterial,
                showForge,
                showReview,
                // showWield,
                showCommand
            }
        } = this.state
        const solution = info.resolution.solution
        const mathml = info.mathml
        const missingVariables = info.fullMissingContext.filter(i => i !== text)
        const columnColorObject = missingVariables.reduce((acc, current) => {
            const res = { ...acc }
            res[current] = getColor(current)
            return res
        }, {})
        columnColorObject[text] = getColor(text)
        return (
            <StyledRoot>
                <StyledMain style={{ width: showSearch ? 'calc(100% - 300px)' : '100%' }}>
                    <Accordion
                        expanded={showForge}
                        toggle={this.toggleForge}
                        label={
                            <Section>
                                <ForgeButton />
                                <Title>Build Equation</Title>
                            </Section>
                        }>
                        <AccordionContents>
                            <StyledObjectCreator>
                                <ObjectCreator
                                    info={{ ...info, name: '' }}
                                    onContextChange={({ text, context }) => this.passCleanedSettingsToHistory(historyObject,
                                        {
                                            text,
                                            context
                                        })}
                                    category={category}
                                    onCategoryChange={event => {
                                        this.setState({ category: event.target.value })
                                    }}
                                    notes={notes}
                                    onNotesChange={event => {
                                        this.setState({ notes: event.target.value })
                                    }}
                                />
                                {solution &&
                                    <StyledSolution style={{ color: columnColorObject[text] }}>
                                        {text} = {formatNumber(solution)}
                                    </StyledSolution>
                                }
                            </StyledObjectCreator>
                        </AccordionContents>
                    </Accordion>
                    <Accordion
                        expanded={showReview}
                        toggle={this.toggleReview}
                        label={
                            <Section>
                                <ReviewButton />
                                <Title>Review</Title>
                            </Section>
                        }>
                        <AccordionContents>
                            <StyledMath>
                                {mathml
                                    ? <MathML title={text} mathml={mathml} />
                                    : <StyledText>
                                        Define your equation. Go to <ForgeButton style={{ height: 40, width: 40, display: 'inline' }} onClick={this.toggleForge} />Build Equation
                                        <br />
                                        <br />
                                    </StyledText>
                                }
                            </StyledMath>
                        </AccordionContents>
                    </Accordion>
                    <Accordion
                        expanded={showMaterial}
                        toggle={this.toggleMaterial}
                        label={
                            <Section>
                                <MaterialButton />
                                <Title>Define Variables</Title>
                            </Section>
                        }>
                        <AccordionContents>
                            <StyledMaterialSection>
                                <StyledTableScrollableLimit>
                                    <StyledTableScrollable
                                        style={{
                                            width: missingVariables.length ? ((missingVariables.length + 1) * (styles.materialColumnWidth + 4) + styles.materialIterationWidth + 6) : '100%'
                                        }}>
                                        {missingVariables.length > 0 &&
                                            <TableGenerator
                                                info={info}
                                                columns={missingVariables}
                                                columnColorObject={columnColorObject}
                                                columnSettings={columnSettings}
                                                settings={generatorSettings}
                                                onGeneratorSettingChange={settings => this.passCleanedSettingsToHistory(historyObject,
                                                    {
                                                        generatorSettings: settings
                                                    })}
                                                onColumnSettingChange={settings => this.passCleanedSettingsToHistory(historyObject,
                                                    {
                                                        columnSettings: settings
                                                    })}
                                                tableGenerated={table => this.setState({ table })} />
                                        }
                                        {missingVariables.length > 0
                                            ? <StyledDataGrid>
                                                <DataGrid header={[['Iteration', ...this.colorVariables({ variables: [...missingVariables, text], columnColorObject })]]}
                                                    numbered
                                                    numberedOffset={generatorSettings.left || 0}
                                                    body={table}
                                                    styles={{
                                                        header: {
                                                            td: { textAlign: 'right' }
                                                        },
                                                        body: {
                                                            edit: {
                                                                width: styles.materialColumnWidth - 10
                                                            },
                                                            td: {
                                                                width: styles.materialColumnWidth,
                                                                textAlign: 'right',
                                                                borderRadius: 2,
                                                                boxShadow: '0px 0px 3px 2px #CCC'
                                                            },
                                                            numbered: { width: styles.materialIterationWidth, textAlign: 'left' }
                                                        }
                                                    }}
                                                    highlightColor='#bfa'
                                                    highlightInitialColor='#7f7'
                                                    hoverHeaderAndNumberedColor='#fc04'
                                                    initialSelectionCell={this.state.initialSelectionCell}
                                                    setInitialSelectionCell={this.setInitialSelectionCell}
                                                    callback={(row, column, data) => {
                                                        table[row][column] = data
                                                        this.setState({ table })
                                                    }} />
                                            </StyledDataGrid>
                                            : <div>
                                                Start by naming your problem. Go to <ForgeButton style={{ height: 40, width: 40, display: 'inline' }} onClick={this.toggleForge} />Build Equation
                                                <br />
                                                <br />
                                                As you break things down, anything named that is left without a value can be defined here.
                                                <br />
                                                <br />
                                            </div>
                                        }
                                    </StyledTableScrollable>
                                </StyledTableScrollableLimit>
                            </StyledMaterialSection>
                        </AccordionContents>
                    </Accordion>
                    <Accordion
                        expanded={showCommand}
                        toggle={this.toggleCommand}
                        label={
                            <Section>
                                <CommandButton />
                                <Title>Graph</Title>
                            </Section>
                        }>
                        <AccordionContents>
                            <StyledGraph>
                                {missingVariables.length > 0
                                    ? <Graph
                                        columns={[...missingVariables, text]}
                                        columnColorObject={columnColorObject}
                                        rows={table}
                                        generatorSettings={generatorSettings} />
                                    : <StyledText>
                                        The graph uses numbers from <MaterialButton style={{ height: 40, width: 40, display: 'inline' }} onClick={this.toggleMaterial} /> Define Variables
                                    </StyledText>
                                }
                            </StyledGraph>
                        </AccordionContents>
                    </Accordion>
                </StyledMain>
                <StyledSearch style={{ width: showSearch ? 300 : 0 }}>
                    {showSearch && <FunctionSearch
                        localFunctions={localStorageFunctions}
                        deleteLocalFunction={this.deleteLocalFunction}
                        toggleSearch={this.toggleSearch}
                    />}
                </StyledSearch>
                <RightFloater style={{ display: 'flex' }}>
                    <MenuButton>
                        <SaveButton
                            hover
                            text={text}
                            location={history.location}
                            onClick={() => {
                                this.saveLocalFunction({ history, name: text, category, notes })
                            }} />
                    </MenuButton>
                    <MenuButton><SearchButton hover active={showSearch} onClick={this.toggleSearch} /></MenuButton>
                </RightFloater>
            </StyledRoot>
        )
    }
}

export default withQsContext(withRouter(Root))
