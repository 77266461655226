import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import ObjectCreator from 'components/ObjectCreator'
import Autocomplete from 'components/Autocomplete'
import { recursiveSolve, stripContext } from 'utils/math'
import { getEquationFromUrl } from 'utils/qs'
import premade from 'utils/premade.json'

const StyledEditCell = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const Label = styled.div`
`

const StyledAutocomplete = styled(Autocomplete)`
    min-height: 1.2rem;
    input {
        font-size: 1rem;
        width: 202px;
        padding-left: 4px;
        background-color: ${styles.inputFocus};
        outline: none;
        height: 40px;
    }
`

const StyledInput = styled.input`
    min-width: 202px;
    width: 202px;
    min-height: 1.2rem;
    background-color: ${styles.inputFocus};
    text-align: right;
`

const Equation = styled.div`
    position: absolute;
    background-color: #f6f6ff;
    left: 210px;
    width: calc(100vw - 248px);
    border-radius: 10px;
    padding: 8px;
    
    @media (max-width:768px) {
        left: 0px;
        top: 45px;
        width: calc(100vw - 28px);
    }
`

const EditActionBar = styled.div`
    display: flex;
    justify-content: space-between;
`

const Section = styled.div`
    display: flex;
`

const Clickable = styled.div`
    margin: 5px;
    cursor: pointer;
    color: ${styles.primaryColor};
`

function EditEquation (props) {
    const {
        editorValue,
        editorRef,
        onChange,
        onKeyDown,
        autocompleteOptions,
        localFunctions,
        saveNewFunction,
        closeEditor
    } = props

    return (
        <StyledEditCell onKeyDown={onKeyDown}>
            <Equation>
                <EditActionBar>
                    <Section>
                        <Clickable onClick={() => closeEditor()}>Save</Clickable>
                        <Clickable onClick={() => closeEditor(false)}>Cancel</Clickable>
                    </Section>
                    <Section>
                        <Clickable onClick={() => {
                            console.log('editorValue', editorValue)
                            saveNewFunction({
                                name: editorValue.value,
                                category: editorValue.category,
                                context: editorValue.info.context,
                                notes: editorValue.notes,
                                text: editorValue.info.text
                            }, localFunctions)
                            closeEditor()
                        }}>Make Reusable</Clickable>
                        <Clickable onClick={() => onChange({ ...editorValue, info: recursiveSolve({ text: '' }) })}>Clear</Clickable>
                    </Section>
                </EditActionBar>
                <ObjectCreator
                    info={{ ...editorValue.info, name: editorValue.value || '' }}
                    onContextChange={info => onChange({ ...editorValue, info: recursiveSolve({ text: info.text, context: stripContext(info.context) }) })}
                    onCategoryChange={event => onChange({ ...editorValue, category: event.target.value })}
                    onNotesChange={event => onChange({ ...editorValue, notes: event.target.value })}
                />
            </Equation>
            <StyledAutocomplete
                editorRef={editorRef}
                value={editorValue.value}
                onChange={value => onChange({ ...editorValue, value })}
                onSelect={functionName => {
                    let newInfo
                    if (localFunctions[functionName] && localFunctions[functionName].url) {
                        newInfo = getEquationFromUrl(localFunctions[functionName].url).context[functionName]
                    } else if (localFunctions[functionName]) {
                        newInfo = { context: localFunctions[functionName].context, text: localFunctions[functionName].text }
                    } else if (premade[functionName]) {
                        newInfo = getEquationFromUrl(premade[functionName].url).context[functionName]
                    }
                    closeEditor(true, { info: newInfo, value: functionName })
                }}
                options={editorValue && editorValue.info && editorValue.info.text !== '' ? [] : autocompleteOptions}
            />
        </StyledEditCell>
    )
}

export default function EditCell (props) {
    const { label } = props
    if (label) {
        const { editorValue, editorRef, onChange, onKeyDown } = props

        const textValue = typeof editorValue === 'object' ? editorValue.value : editorValue

        return (
            <StyledEditCell onKeyDown={onKeyDown}>
                <Label>{label}</Label>
                <StyledInput
                    ref={editorRef}
                    onChange={event => {
                        onChange(event.target.value)
                    }}
                    value={textValue === undefined ? '' : textValue} />
            </StyledEditCell>
        )
    } else {
        return <EditEquation {...props} />
    }
}
