import * as React from 'react'
import { mathmlWrapper } from 'utils/math/mathml'
import styled from 'styled-components'

interface Props {
    mathml: string,
    title: string
}

const StyledMathML = styled.div`
    text-align: center;
    padding: 10px;
    overflow-x: auto;
    overflow-y: hidden;
`

class MathML extends React.Component<Props, {}> {
    componentDidMount () {
        // MathJax.Hub.Queue(['Typeset', MathJax.Hub]) // eslint-disable-line
        if (typeof MathJax !== 'undefined') { // eslint-disable-line
            MathJax.typesetPromise() // eslint-disable-line
        }
    }

    componentDidUpdate () {
        // MathJax.Hub.Queue(['Typeset', MathJax.Hub]) // eslint-disable-line
        if (typeof MathJax !== 'undefined') { // eslint-disable-line
            MathJax.typesetClear() // eslint-disable-line
            MathJax.typesetPromise() // eslint-disable-line
        }
    }

    render () {
        const { mathml, title } = this.props

        return (
            <StyledMathML dangerouslySetInnerHTML={{ __html: (mathmlWrapper({ title, text: mathml })) }} />
        )
    }
}

export default MathML
