import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'

const StyledHelpButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
`

const StyledHelpButtonWithHover = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Help', 0)}
`

export default function HelButton (props) {
    if (props.hover) {
        return (
            <StyledHelpButtonWithHover {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <i className='material-icons' style={props.style}>help_outline</i>
            </StyledHelpButtonWithHover>
        )
    } else {
        return (
            <StyledHelpButton {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <i className='material-icons' style={props.style}>help_outline</i>
            </StyledHelpButton>
        )
    }
}
