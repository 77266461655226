import React from 'react'
import styled from 'styled-components'
import { formatNumber } from 'utils/math'

const StyledCell = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    width: 200px;
    margin: 2px;
`

const Label = styled.div`
    margin-left: 2px;
    min-height: 1.2rem;
`

const Value = styled.div`
    margin-left: 2px;
    text-align: right;
`

const InheritedValue = styled.div`
    color: gray;
    margin-left: 2px;
    text-align: right;
`

const Solution = styled.div`
    margin-right: 2px;
    text-align: right;
`

export default function Cell (props) {
    const { value, info } = props
    if (info) { // if there's info then it must be the root
        const { solution } = props
        return (
            <StyledCell>
                <Label>
                    {value}
                </Label>
                <Solution>
                    {formatNumber(solution)}
                </Solution>
            </StyledCell>
        )
    } else {
        const { label, isInherited } = props
        return (
            <StyledCell>
                <Label>
                    {label}
                </Label>
                {isInherited
                    ? <InheritedValue>
                        {formatNumber(value)}
                    </InheritedValue>
                    : <Value>
                        {value === '' ? 'undefined' : formatNumber(value)}
                    </Value>
                }
            </StyledCell>
        )
    }
}
