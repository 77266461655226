import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as styles from 'styles'

const StyledMessage = styled.div`
    min-height: 1px;
    overflow: auto;
    width: 100%;
    text-align: center;
`

const Title = styled.div`
    font-size: 1.5rem;
    margin-top: 10px;
`

const StyledLine = styled.div`
    padding-top: 8px;
`

const StyledLink = styled.div`
    margin: 20px;
    a {
        color: ${styles.primaryColor};
    }
`

const Overview = () => {
    return (
        <StyledMessage>
            <br />
            <Title style={{ fontWeight: 'bold' }}>Mathite</Title>
            <br />
            <StyledLine>{'Like an online graphing calculator.'}</StyledLine>
            <StyledLine>{'Where spreadsheet meets database.'}</StyledLine>
            <br />
            <br />
            <StyledLine>{'Create and share functions.'}</StyledLine>
            <StyledLine>{'Store data.'}</StyledLine>
            <StyledLine>{'Apply functions to data.'}</StyledLine>
            <StyledLine>{'Create graphs.'}</StyledLine>
            <br />
            <br />
            <StyledLink>
                <Link to='eq'>{'Create a reusable function'}</Link>
            </StyledLink>
            <StyledLink>
                <Link to='sheet'>{'Use functions and create more'}</Link>
            </StyledLink>
            <br />
            <br />
            <StyledLink>
                <Link to='draw'>{'Take a break and draw'}</Link>
            </StyledLink>
            <br />
            <br />
            <br />
            <br />
            {/* <StyledLink>
                <Link to='data'>{'Test the data view'}</Link>
            </StyledLink> */}
        </StyledMessage>
    )
}

export default Overview
