import React, { useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import DataGrid from 'components/DataGrid'
import Cell from 'components/Cell'
import EditCell from 'components/EditCell'
import { recursiveSolve, stripContext } from 'utils/math'
import { getLocalStorage, setLocalStorage, useLocalStorage } from 'utils/localStorage'
import premade from 'utils/premade.json'

const StyledSheet = styled.div`
    min-height: 1px;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Top = styled.div`
    display: flex;
    width: 100%;
    min-height: 42px;
    background-color: ${styles.lightGray};
    ${'' /* box-shadow: 0px 4px 8px #888;
    border-radius: 3px; */}
`

const Bottom = styled.div`
    display: flex;
    overflow: auto;
    flex-grow: 1;
`

const Left = styled.div`
    width: 200px;
    min-width: 200px;
`

const Label = styled.div`
    margin-top: 4px;
    margin-left: 8px;
    font-size: 1.1rem;
`

const Result = styled.div`
    position: absolute;
    font-size: 1.1rem;
    top: 78px;
    left: 150px;
`

const RowSection = styled.div`
    display: flex;
    margin-left: auto;
`

const RowButton = styled.div`
    margin: 5px;
    cursor: pointer;
    color: ${styles.primaryColor};
`

export default function Sheet (props) {
    const [localFunctions, setLocalFunctions] = useLocalStorage({}, 'localFunctions')
    const [dataGridEdit, setDataGridEdit] = useState({})
    const [equationArray, dispatchEquation] = useReducer(equationReducer, { gridBody: [] })
    const [initialSelectionCell, setInitialSelectionCell] = useState({})

    useEffect(() => {
        dispatchEquation({
            type: 'equationInitialization',
            equations: getLocalStorage('localEquations') || []
        })
    }, [])

    function equationReducer (state, action) {
        const res = {}
        if (action.type === 'equationInitialization') {
            res.equations = action.equations
        } else if (action.type === 'equationUpdateCell') {
            const { row, column, data } = action
            const newEquations = [...state.equations]
            if (column === 0) {
                if (data) {
                    newEquations[row] = data
                } else {
                    newEquations[row] = { value: '' }
                }
            } else {
                if (!newEquations[row].localContext) {
                    newEquations[row].localContext = {}
                }
                if (data === undefined || data === '') {
                    delete newEquations[row].localContext[state.variableMap[row][column]]
                } else {
                    newEquations[row].localContext[state.variableMap[row][column]] = data
                }
            }
            res.equations = newEquations
        } else if (action.type === 'equationSet') {
            const { info, row } = action
            const newEquations = [...state.equations]
            newEquations[row].text = info.text
            newEquations[row].context = info.context
            newEquations[row].category = info.category
            newEquations[row].notes = info.notes
            res.equations = newEquations
        } else if (action.type === 'addRow') {
            const newEquations = [...state.equations]
            const index = action.index === undefined ? newEquations.length : action.index
            res.equations = newEquations.slice(0, index).concat([{ value: '' }], newEquations.slice(index))
        } else if (action.type === 'deleteRow') {
            const newEquations = [...state.equations]
            const index = action.index === undefined ? newEquations.length - 1 : action.index
            newEquations.splice(index, 1)
            res.equations = newEquations
        }
        res.equations = setEquations(res.equations)
        Object.assign(res, { ...getGridBodyMap(res.equations) })
        return res
    }

    function saveNewFunction (newFunction, existingFunctions) {
        const { name, category, context, notes, text } = newFunction
        const newFunctions = Object.assign({}, existingFunctions)
        newFunctions[name] = {
            category,
            context: stripContext(context),
            notes,
            text,
            date: Date.now()
        }
        setLocalFunctions(newFunctions)
    }

    function setEquations (equations) {
        const newEquations = [...equations]
        // fill with blank rows
        if (!newEquations.length) {
            for (let iterator = 0; iterator <= 20; iterator++) {
                newEquations.push({ value: '' })
            }
        }
        setLocalStorage('localEquations', newEquations)
        return newEquations
    }

    function getGridBodyMap (equations) {
        const variableMap = []
        const sheetContext = {}
        const gridBody = equations.map((equationObject, eqIndex) => {
            const name = equationObject.value
            variableMap.push([name])
            const { text, context = {} } = equationObject.info || {}
            const localContext = equationObject.localContext
            const inheritedContext = Object.assign({}, sheetContext, localContext)
            const info = recursiveSolve({ text, context: stripContext(context), inheritedContext })
            if (!info.resolution.error) {
                sheetContext[name] = info.resolution.solution
            }
            const uniqueMissingContext = info.fullMissingContext && info.fullMissingContext.reduce((prev, curr) => {
                if (prev.includes(curr)) {
                    return prev
                } else {
                    return prev.concat(curr)
                }
            }, [])
            const res = [{
                // Props passed into (edit) cell component
                info,
                autocompleteOptions: Object.keys(Object.assign({}, localFunctions, premade)).map(i => ({ id: i })),
                localFunctions,
                saveNewFunction,
                solution: info.resolution.error
                    ? `Missing: ${uniqueMissingContext.reduce((prev, curr) => {
                        if (inheritedContext[curr]) {
                            return prev
                        } else {
                            return prev.concat(curr)
                        }
                    }, []).join(', ')}`
                    : info.resolution.solution,
                value: name
            }]
            if (uniqueMissingContext) {
                uniqueMissingContext.forEach(key => {
                    variableMap[eqIndex].push(key)
                    res.push({
                        label: key,
                        value: '' + (inheritedContext[key] || (context[key] && context[key].text)),
                        isInherited: !localContext || !localContext[key]
                    })
                })
            }
            return res
        })
        return {
            gridBody,
            variableMap
        }
    }

    return (
        <StyledSheet>
            <Top>
                <Left>
                    <Label>Name</Label>
                    <Result>Value</Result>
                </Left>
                <RowSection>
                    <RowButton onClick={() => dispatchEquation({ type: 'addRow', index: initialSelectionCell.row })}>Add Row</RowButton>
                    <RowButton onClick={() => dispatchEquation({ type: 'deleteRow', index: initialSelectionCell.row })}>Delete Row</RowButton>
                </RowSection>
            </Top>
            <Bottom>
                <DataGrid
                    editState={dataGridEdit}
                    setEdit={editState => {
                        if (editState.column === 0 && typeof editState.current === 'string') {
                            const info = recursiveSolve({ text: '' })
                            const newCurrent = {
                                info,
                                value: editState.current
                            }
                            editState.original = newCurrent
                            editState.current = newCurrent
                        }
                        setDataGridEdit(editState)
                    }}
                    body={equationArray.gridBody}
                    styles={{
                        body: {
                            edit: {
                                borderRadius: 2,
                                boxShadow: '0px 0px 3px 2px #CCC'
                            },
                            td: {
                                borderRadius: 2,
                                boxShadow: '0px 0px 3px 2px #CCC'
                            }
                        }
                    }}
                    initialSelectionCell={initialSelectionCell}
                    setInitialSelectionCell={setInitialSelectionCell}
                    highlightInitialColor={styles.lightOrange}
                    highlightColor={styles.extraLightOrange}
                    callback={(row, column, data) => {
                        dispatchEquation({ type: 'equationUpdateCell', row, column, data })
                    }}
                    cellComponent={Cell}
                    editComponent={EditCell}
                    skipTabIndexes={[0]}
                />
            </Bottom>
        </StyledSheet>
    )
}
