import React from 'react'
import ReactDOM from 'react-dom'
import App from 'views/App'
import registerServiceWorker from 'utils/registerServiceWorker'

ReactDOM.render(
    <App />,
    document.getElementById('app')
)
registerServiceWorker()
