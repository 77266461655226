import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import ignoreList from 'utils/math/ignoreList'
import anvil from 'icons/anvil.svg'
import pickaxe from 'icons/pickaxe.svg'
// import sword from 'icons/swords.svg'
import crown from 'icons/crown.svg'
import easel from 'icons/easel.svg'

const StyledHelp = styled.div`
    background: ${styles.extraLightGray};
    width: 100%;
    border-radius: 9px;
    height: 100%;
`

const Body = styled.div`
    padding: 20px;
    overflow-y: auto;
    height: calc(100% - 88px);
`

const Close = styled.div`
    cursor: pointer;
    color: black;
    position: absolute;
    right: 10px;
    i {
        margin-top: 5px;
        font-size: 2rem;
        border-radius: 20px;
        :hover {
            background: white;
        }
    }
`

const IgnoreList = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

const Item = styled.div`
    width: 100px;
    padding: 4px;
`

const Text = styled.div`
    padding: 6px;
`

const StyledLink = styled.div`
    display: contents;
    cursor: pointer;
`

const Section = styled.div`
    display: flex;
    padding-bottom: 24px;
`

const VerticalGroup = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    font-size: 1.4rem;
    padding-bottom: 8px;
`

const Line = styled.div`
    font-size: 1rem;
    padding-bottom: 8px;
`

export default function HelpEquation (props) {
    const {
        toggleHelp
    } = props
    return (
        <StyledHelp>
            <Close onClick={toggleHelp}>
                <i className='material-icons'>close</i>
            </Close>
            <Title style={{ padding: 10 }}>
                Help
            </Title>
            <Body>
                <Text>
                    <Section>
                        <VerticalGroup>
                            <Line>{'Show the sections that best fit your needs throughout your workflow.'}</Line>
                            <Line>{'Easily share work with others by sending them the url for the current equation.'}</Line>
                        </VerticalGroup>
                    </Section>
                    <Section>
                        <img src={pickaxe} alt='Material' height={40} width={40} />
                        <VerticalGroup>
                            <Title>{'Define Variables'}</Title>
                            <Line>{'Dynamically generate values for all of the undefined variables in your equations to be used with the command module.'}</Line>
                        </VerticalGroup>
                    </Section>
                    <Section>
                        <img src={anvil} alt='Forge' height={40} width={40} />
                        <VerticalGroup>
                            <Title>{'Build Equation'}</Title>
                            <Line>{'Any textboxes in the first section (except Notes) will be used to create and solve a math problem.'}</Line>
                            <Line>{'Alphabetical characters will be used as variables, and will add more blank textboxes.'}</Line>
                            <Line>{'Variables can be defined as numbers, equations, more variables or any combination.'}</Line>
                            <Line>{'Numbers before words will be used to multiply the variable.'}</Line>
                            <Line>{'Numbers at the end of words will be used as part of the variable name as a subscript.'}</Line>
                            <Line>{'Operators (+-*/()) separate variables.'}</Line>
                        </VerticalGroup>
                    </Section>
                    <Section>
                        <img src={easel} alt='Review' height={40} width={40} />
                        <VerticalGroup>
                            <Title>{'Review'}</Title>
                            <Line>{'Visually validate your equation.'}</Line>
                        </VerticalGroup>
                    </Section>
                    {/* <Section>
                        <img src={sword} alt='Wield' height={40} width={40} />
                        <VerticalGroup>
                            <Title>{'Wield module'}</Title>
                        </VerticalGroup>
                    </Section> */}
                    <Section>
                        <img src={crown} alt='Command' height={40} width={40} />
                        <VerticalGroup>
                            <Title>{'Graph'}</Title>
                            <Line>{'Graph your equation using variables left unspecified in the Equation section.'}</Line>
                            <Line>{'Quickly see the changes that occur as you manipulate the Defined Variables (materials).'}</Line>
                            <Line>{'Click on the labels for different lines to show/hide.'}</Line>
                        </VerticalGroup>
                    </Section>
                    <Section>
                        <i style={{ fontSize: 40 }} className='material-icons'>search</i>
                        <VerticalGroup>
                            <Title>{'Search'}</Title>
                            <Line>{'Save functions on your device.'}</Line>
                            <Line>{"Be specific. It's easy to save a bunch of problems and then delete them later. But names must be unique."}</Line>
                            <Line>{'Search saved functions and review your notes.'}</Line>
                            <Line>{'Add the subject or other relevant tags to the beginning of notes to make them easier to find.'}</Line>
                            <Line>{'There are no predefined categories. You make the categories by the notes and variables you create.'}</Line>
                            <Line>{'Try typing "finance" into the search and see what premade functions are already there.'}</Line>
                        </VerticalGroup>
                    </Section>
                    <br />
                    <Line>{'Functions that cannot be used as variables:'}</Line>
                </Text>
                <IgnoreList>
                    {ignoreList.map(func => {
                        return <Item key={func}>{func}</Item>
                    })}
                </IgnoreList>
                <Text>
                    <br />
                    Checkout <StyledLink><a href="http://mathjs.org/docs/reference/functions.html" target='_blank' rel='noopener noreferrer'>http://mathjs.org/docs/reference/functions.html</a></StyledLink> for an explanation of what the builtin functions do.
                </Text>
            </Body>
        </StyledHelp>
    )
}
