import React from 'react'
import * as styles from 'styles'
import DataGrid from 'components/DataGrid'

export default function DataView (props) {
    return <DataGrid
        body={[[1]]}
        styles={{
            body: {
                edit: {
                    borderRadius: 2,
                    boxShadow: '0px 0px 3px 2px #CCC'
                },
                td: {
                    width: 200,
                    borderRadius: 2,
                    boxShadow: '0px 0px 3px 2px #CCC'
                }
            }
        }}
        highlightInitialColor={styles.lightGreen}
        highlightColor={styles.extraLightGreen}
    />
}
