import { evaluate } from 'mathjs'
import ignoreList from './ignoreList'
import { extrapolateMathml, expandVariableWithColor, colorVariable } from './mathml'

const circularDependencyError = 'Circular dependency detected'

export const variableRegex = /([A-Za-z]([A-Za-z0-9 ]*[A-Za-z0-9])*)/g

// parses the text and returns identified variables
export function getVariables ({
    text,
    useIgnoreList = true
}: {
    text: string,
    useIgnoreList?: boolean
}): Array<string> {
    if (!text) return []
    const matches = text.match(variableRegex)
    if (!matches) return []
    const uniqueMatches = matches.reduce((prev, curr) => {
        if (!prev.includes(curr)) {
            return [...prev, curr]
        } else {
            return prev
        }
    }, [])

    if (!useIgnoreList) return uniqueMatches

    return uniqueMatches.reduce((prev, curr) => {
        if (!ignoreList.includes(curr)) {
            return [...prev, curr]
        } else {
            return prev
        }
    }, [])
}

export function recursiveSolve ({
    text = '',
    context,
    inheritedContext,
    parents = []
}: {
    text: string,
    context: Object,
    inheritedContext: Object,
    parents: Array<string>
}): {
        text: string,
        context: Object,
        resolution: { solution?: string, special?: string, error?: string},
        solvableEquation: string,
        mathml?: string,
        missingContext: Array<string>,
        requiredContext: Array<string>,
        fullMissingContext: Array<string>
    } {
    let solvableEquation = text
    let mathml = extrapolateMathml({ text })
    const missingContext = []
    const fullMissingContext = []
    const variables: any = getVariables({ text })
    const requiredContext = [...variables]
    const solvableContext = { ...context }
    const response = {
        text,
        missingContext,
        fullMissingContext,
        requiredContext,
        context: solvableContext
    }
    const resolution = parents.reduce((error, parent) => {
        if (error.error === circularDependencyError || variables.includes(parent)) {
            return { error: circularDependencyError }
        }
        return error
    }, {})
    if (resolution.error) {
        return {
            ...response,
            resolution,
            solvableEquation,
            mathml
        }
    }
    variables.sort((a, b) => b.length - a.length).forEach(variable => {
        if (solvableContext[variable] && solvableContext[variable].text) {
            const usableContextVariable = solvableContext[variable] || inheritedContext[variable]
            const prepared = recursiveSolve({
                text: usableContextVariable.text,
                context: usableContextVariable.context,
                inheritedContext: { ...inheritedContext, ...solvableContext },
                parents: [...parents, variable]
            })
            fullMissingContext.push(...prepared.fullMissingContext)
            if (prepared.resolution.error === circularDependencyError) {
                resolution.error = circularDependencyError
                return
            }
            solvableContext[variable] = prepared
            solvableEquation = solvableEquation.replace(new RegExp(variable, 'g'), '(' + prepared.solvableEquation + ')')
            if (prepared.mathml && variableRegex.test(prepared.mathml)) {
                mathml = expandVariableWithColor({ text: mathml, variable, expanded: prepared.mathml })
            } else {
                mathml = colorVariable({ text: mathml, variable })
            }
        } else if (inheritedContext && inheritedContext[variable] && inheritedContext[variable].solvableEquation !== undefined) {
            solvableEquation = solvableEquation.replace(new RegExp(variable, 'g'), '(' + inheritedContext[variable].solvableEquation + ')')
            mathml = colorVariable({ text: mathml, variable })
        } else if (inheritedContext && inheritedContext[variable] && !inheritedContext[variable].text) {
            solvableEquation = solvableEquation.replace(new RegExp(variable, 'g'), '(' + inheritedContext[variable] + ')')
            mathml = colorVariable({ text: mathml, variable })
            fullMissingContext.push(variable)
        } else {
            missingContext.push(variable)
            fullMissingContext.push(variable)
            mathml = colorVariable({ text: mathml, variable })
        }
    })
    if (resolution.error) {
        return {
            ...response,
            resolution,
            solvableEquation,
            mathml
        }
    }
    return {
        ...response,
        resolution: solve({ text: solvableEquation, solvableEquation }),
        solvableEquation,
        mathml
    }
}

// Context in this case is simply an object with variableNames as properties with the values that should be used
export function solveWithAddedContext ({ text, simpleContext }: { text: string, simpleContext: Object }): {
    solution?: string, special?: string, error?: string, missingContext?: Array<string>
} {
    let res = text
    const variableNames = getVariables({ text })
    variableNames.sort((a, b) => b.length - a.length).forEach(variable => {
        res = res.replace(new RegExp(variable, 'g'), `(${simpleContext[variable]})`)
    })
    return solve({ text: res })
}

export function solve ({
    text
}: {
    text: string
}): {
    solution?: string, special?: string, error?: string, missingContext?: Array<string>
} {
    if (!text || text.length === 0) {
        return {}
    }
    let solution
    const missingContext = getVariables({ text })
    if (missingContext.length > 0) {
        return { error: 'Missing context', missingContext }
    }
    try {
        solution = evaluate(text)
    } catch (error) {
        return { error }
    }
    if (solution !== undefined && isNaN(parseFloat(solution))) return { error: 'Error. Not a number.' }
    if (solution.im) {
        return {
            solution: solution.im,
            special: 'im'
        }
    }
    return { solution }
}

export function stripContext (context: Object) {
    const res = {}
    if (typeof context !== 'object') {
        return context
    }
    let hasProps = false
    Object.keys(context).forEach(prop => {
        if (context[prop] && (context[prop].text || context[prop].context)) {
            res[prop] = {}
            res[prop].text = context[prop].text
            res[prop].context = stripContext(context[prop].context)
            if (res[prop].context || res[prop].text) {
                hasProps = true
            }
        }
    })
    if (!hasProps) {
        return undefined
    }
    return res
}

export function formatNumber (number) {
    if (number && number.toString) {
        const str = number.toString()
        if (/\./.test(str)) {
            const parts = str.split('.')
            return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + parts[1]
        } else {
            return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    } else {
        return number
    }
}
