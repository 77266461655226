import React from 'react'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'

const StyledLine = styled.div`
    padding-left: 1rem;
`

export default function Graph (props) {
    const {
        columns,
        columnColorObject,
        rows,
        generatorSettings
    } = props
    const left = parseInt(generatorSettings.left || 0, 10)
    const right = parseInt(generatorSettings.right || 10, 10)
    const labels = []
    let iteration = -1 * left
    const xAxisLabel = columns.length === 2
    while (iteration <= right) {
        if (xAxisLabel) {
            labels.push(rows[iteration + left][0])
        } else {
            labels.push(iteration)
        }
        iteration++
    }
    const datasets = columns.map((column, index) => {
        return {
            label: column,
            lineTension: 0,
            fill: false,
            backgroundColor: columnColorObject[column],
            borderColor: columnColorObject[column],
            pointBorderColor: columnColorObject[column],
            pointBackgroundColor: columnColorObject[column],
            data: rows.map(i => i[index]),
            hidden: index !== columns.length - 1
        }
    })
    const graphData = {
        labels,
        datasets
    }
    return (
        <StyledLine>
            <Line redraw={true} data={graphData} />
        </StyledLine>
    )
}
