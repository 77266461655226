import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

const StyledFrozenTd = styled.div`
`

const FrozenColumnTd = props => {
    return (
        <ColumnTd>
            <StyledFrozenTd {...props} />
        </ColumnTd>
    )
}

const ColumnTd = styled.td`
`

let overrideSelect = false

export default function DataGrid (props: {
    callback?: Function,
    header?: Array<Array<string>>,
    body: Array<Array<string>>,
    footer?: Array<Array<string>>,
    numbered?: boolean,
    highlightColor?: string,
    highlightInitialColor?: string,
    hoverHeaderAndNumberedColor?: string,
    className?: string,
    style?: string,
    cellComponent?: React.node,
    frozenColumns?: number,
    frozenColumnWidth?: number,
    styles?: {
        header?: {
            tr?: Object,
            td?: Object
        },
        body?: {
            tr?: Object,
            td?: Object,
            edit?: Object
        },
        footer?: {
            tr?: Object,
            td?: Object
        },
        thead?: Object,
        tbody?: Object,
        tfoot?: Object
    },
    classes?: {
        header?: {
            tr?: string,
            td?: string
        },
        body?: {
            tr?: string,
            td?: string,
            edit?: string
        },
        footer?: {
            tr?: string,
            td?: string
        },
        thead?: string,
        tbody?: string,
        tfoot?: string
    }
}) {
    const {
        body,
        frozenColumns,
        editState = {},
        setEdit,
        keepEditing,
        skipTabIndexes,
        initialSelectionCell,
        setInitialSelectionCell,
        shouldHandleBlur = false
    } = props
    const CellComponent = props.cellComponent
    const EditCellComponent = props.editComponent

    const [clickedCell, setClickedCell] = useState({})
    const [selectionRange, setSelectionRange] = useState({})
    const [selectionCurrent, setSelectionCurrent] = useState({})
    const [hover, setHover] = useState({})
    const [selection, setSelection] = useState()
    const [mouseDown, setMouseDown] = useState(false)
    const [blurredOnce, setBlurredOnce] = useState(false)
    const [shiftModifier, setShiftModifier] = useState(false)
    const [ctrlModifier, setCtrlModifier] = useState(false)

    const editorRef = useRef()
    const selectionRef = useRef()

    useEffect(() => {
        setSelection(body.filter(filterRow).map(tabularizeSelection).join('\n'))
    }, [body, selectionRange]) // eslint-disable-line

    useEffect(() => {
        if (selection) {
            selectText()
        }
    }, [selection])

    useEffect(() => {
        if (editState.row !== undefined && editState.column !== undefined) {
            const ref = editorRef.current
            ref.focus()
            if (overrideSelect) {
                overrideSelect = false
            } else {
                ref.setSelectionRange(0, ref.value.length)
            }
        } else {
            selectText()
        }
    }, [editState.row, editState.column])

    function selectText () {
        const ref = selectionRef.current
        ref.focus()
        ref.setSelectionRange(0, ref.value.length)
    }

    function componentKeyDown (event) {
        if (event.key === 'Shift') {
            setShiftModifier(true)
        }
        if (event.key === 'Control') {
            setCtrlModifier(true)
        }
    }

    function componentKeyUp (event) {
        if (event.key === 'Shift') {
            setShiftModifier(false)
        }
        if (event.key === 'Control') {
            setCtrlModifier(false)
        }
    }

    function tableMouseUp () {
        setMouseDown(false)
    }

    function gridMouseMove (event, row, column) {
        setHover({
            row: row === undefined ? -1 : row,
            column: column === undefined ? -1 : column
        })
        if (mouseDown) {
            // If row is null then it should be a header column event; use the body length
            // If column is null then it should be a numbered column event; use the body column count
            setRangeSelection(row === undefined ? body.length : row,
                column === undefined ? body[0].length : column, true)
        }
    }

    function gridMouseDown (event, row, column) {
        event.preventDefault()
        setMouseDown(true)
        setBlurredOnce(false)
        // reset clicked if it's not the last clicked cell, so that it won't open the editor
        setClickedCell({
            row: clickedCell.row === row ? row : undefined,
            column: clickedCell.column === column ? column : undefined
        })
        if (shiftModifier) {
            // If row is null then it should be a header column event; use the body length
            // If column is null then it should be a numbered column event; use the body column count
            setRangeSelection(row === undefined ? body.length : row,
                column === undefined ? body[0].length : column, true)
        } else {
            setSingleSelection(row, column, true)
        }
    }

    function gridMouseUp (event, row, column) {
        // clickedCell.row and clickedCell.column store the last clicked cell, if it's the same open the editor
        if (clickedCell.row === row && clickedCell.column === column) editCell(row, column)
        // if the mouse up event fires on the same cell that was moused down on,
        // then save it as the clicked cell in state
        else if (initialSelectionCell.row === row && initialSelectionCell.column === column) {
            setClickedCell({ row, column })
        }
    }

    function selectionKeyDown (event) {
        if (event.key === 'Enter') {
            editCell(initialSelectionCell.row, initialSelectionCell.column)
        }
        if (event.key === 'Tab') {
            event.preventDefault()
            if (shiftModifier) {
                setSingleSelection(initialSelectionCell.row, initialSelectionCell.column - 1)
            } else {
                setSingleSelection(initialSelectionCell.row, initialSelectionCell.column + 1)
            }
        }
        if (event.key === 'Escape') {
            if (editState.row !== undefined && editState.column !== undefined) {
                closeEditor()
            } else {
                resetSelection()
            }
        }
        if (event.key === 'Delete') {
            const deleteArray = []
            let row = selectionRange.startRow
            let column = selectionRange.startColumn
            const columnArray = []
            while (column <= selectionRange.endColumn) {
                if (column > -1) {
                    columnArray.push('')
                }
                column++
            }
            while (row <= selectionRange.endRow) {
                if (row > -1) {
                    deleteArray.push(columnArray)
                }
                row++
            }
            updateArray(selectionRange.startRow, selectionRange.startColumn, body, deleteArray)
        }
        if (event.key === 'Backspace') {
            props.callback(initialSelectionCell.row, initialSelectionCell.column, '')
        }
        if (event.key === 'ArrowUp') {
            // if shift: expand range; else: move selection and initial
            if (shiftModifier) {
                setRangeSelection(selectionCurrent.row - 1, selectionCurrent.column)
            } else {
                setSingleSelection(initialSelectionCell.row - 1, initialSelectionCell.column)
            }
        }
        if (event.key === 'ArrowDown') {
            if (shiftModifier) {
                setRangeSelection(selectionCurrent.row + 1, selectionCurrent.column)
            } else {
                setSingleSelection(initialSelectionCell.row + 1, initialSelectionCell.column)
            }
        }
        if (event.key === 'ArrowLeft') {
            if (shiftModifier) {
                setRangeSelection(selectionCurrent.row, selectionCurrent.column - 1)
            } else {
                setSingleSelection(initialSelectionCell.row, initialSelectionCell.column - 1)
            }
        }
        if (event.key === 'ArrowRight') {
            if (shiftModifier) {
                setRangeSelection(selectionCurrent.row, selectionCurrent.column + 1)
            } else {
                setSingleSelection(initialSelectionCell.row, initialSelectionCell.column + 1)
            }
        }
        if (ctrlModifier) {
            if (event.key === 'a') setSingleSelection(null, null)
        } else {
            // If ctrlModifier is false
            if (event.key.length === 1) {
                editCell(initialSelectionCell.row, initialSelectionCell.column, event.key)
                overrideSelect = true
            }
        }
    }

    function resetSelection () {
        setInitialSelectionCell({})
        setSelectionCurrent({})
        setSelectionRange({})
        setClickedCell({})
        setBlurredOnce(false)
        setSelection('')
    }

    function selectionBlurred () {
        // Blurs once (from the input element) when you click on a cell, and drag
        // Blurs again if you click and drag outside of the table scope and click on something
        // If the mouse down event isn't fired when the input is blurred they didn't
        // click anywhere on the table, so it should reset the selection and lose focus

        // Lost focus
        if (!mouseDown || blurredOnce) {
            setCtrlModifier(false)
            setShiftModifier(false)
            setMouseDown(false)
            resetSelection()
        } else {
            setBlurredOnce(true)
        }
    }

    function selectionPaste (event) {
        event.clipboardData.items[0].getAsString(text => {
            const rows = text.split('\n')
            const data = text.match(/\t/g)
                ? rows.map((row) => row.split('\t'))
                : rows.map((row) => row.split(','))
            updateArray(
                selectionRange.startRow,
                selectionRange.startColumn,
                body,
                data
            )
        })
    }

    function setSingleSelection (rowIndex: number, columnIndex: number, forceSelection?: boolean) {
        const row = rowIndex ? rowIndex === -1 && columnIndex === -1 ? -1 : cleanRow(rowIndex) : 0
        const column = columnIndex ? rowIndex === -1 && columnIndex === -1 ? -1 : cleanColumn(row, columnIndex) : 0
        setInitialSelectionCell({ row, column })
        setSelectionCurrent({ row, column })
        setSelectionRange({
            startRow: row,
            endRow: rowIndex !== undefined ? row : body.length,
            startColumn: column,
            endColumn: columnIndex !== undefined ? column : body[0].length
        })
        if (forceSelection) {
            selectText()
        }
    }

    function setRangeSelection (rowIndex: number, columnIndex: number, forceSelection?: boolean) {
        const row = cleanRow(rowIndex)
        const column = cleanColumn(row, columnIndex)
        setSelectionCurrent({ row, column })
        setSelectionRange({
            startRow: row < initialSelectionCell.row ? row : initialSelectionCell.row,
            endRow: row < initialSelectionCell.row ? initialSelectionCell.row : row,
            startColumn: column < initialSelectionCell.column ? column : initialSelectionCell.column,
            endColumn: column < initialSelectionCell.column ? initialSelectionCell.column : column
        })
        if (forceSelection) {
            selectText()
        }
    }

    function editorKeyDown (event) {
        if (event.key === 'Escape') {
            closeEditor(false)
        }
        if (event.key === 'Enter') {
            if (keepEditing) {
                if (shiftModifier) {
                    editCell(editState.row - 1, editState.column)
                } else {
                    editCell(editState.row + 1, editState.column)
                }
            } else {
                closeEditor()
                if (shiftModifier) {
                    setSingleSelection(editState.row - 1, editState.column)
                } else {
                    setSingleSelection(editState.row + 1, editState.column)
                }
            }
        }
        if (event.key === 'Tab' && (!skipTabIndexes || !skipTabIndexes.includes(editState.column))) {
            event.preventDefault()
            if (keepEditing) {
                if (shiftModifier) {
                    editCell(editState.row, editState.column - 1)
                } else {
                    editCell(editState.row, editState.column + 1)
                }
            } else {
                closeEditor()
                if (shiftModifier) {
                    setSingleSelection(editState.row, editState.column - 1)
                } else {
                    setSingleSelection(editState.row, editState.column + 1)
                }
            }
        }
        if (event.key === 's') {
            if (ctrlModifier) {
                event.preventDefault()
                closeEditor()
            }
        }
    }

    function editCell (rowIndex, columnIndex, startingValue) {
        if (editState.original !== undefined && editState.row !== undefined && editState.column !== undefined &&
            (CellComponent ? editState.original.value : editState.original) !== (CellComponent ? editState.current.value : editState.current)) {
            props.callback(editState.row, editState.column, editState.current)
        }
        if (rowIndex >= 0 && columnIndex >= 0) {
            const row = cleanRow(rowIndex)
            const column = cleanColumn(row, columnIndex)
            const original = startingValue !== undefined ? startingValue : body[row][column]
            setEdit({ row, column, original, current: original })
        }
    }

    function closeEditor (save = true, valueOverride) {
        if (save) {
            props.callback(editState.row, editState.column, valueOverride || editState.current)
        }
        setEdit({})
        setSingleSelection(editState.row, editState.column)
    }

    function updateArray (startRow, startColumn, originalArray, newArrayData) {
        return originalArray.map((row, rowIndex) => updateArrayRow(row, startColumn, newArrayData[rowIndex - startRow], rowIndex))
    }

    function updateArrayRow (row, startColumn, newArrayData, rowIndex) {
        if (newArrayData !== undefined) {
            return Object.assign([], row.map((cell, cellIndex) => updateArrayCell(cell, newArrayData[cellIndex - startColumn], rowIndex, cellIndex)))
        } else {
            return row
        }
    }

    function updateArrayCell (cell, data, rowIndex, cellIndex) {
        if (data !== undefined) {
            props.callback(rowIndex, cellIndex, data)
            return data
        } else {
            return cell
        }
    }

    function cleanRow (row) {
        return row > body.length - 1
            ? body.length - 1
            : row < 0 ? 0 : row
    }

    function cleanColumn (row, column) {
        return column > body[row].length - 1
            ? body[row].length - 1
            : column < 0 ? 0 : column
    }

    function filterRow (row, rowIndex) {
        if (rowIndex >= selectionRange.startRow &&
            rowIndex <= selectionRange.endRow) {
            return true
        } else {
            return false
        }
    }

    function tabularizeSelection (row) {
        return row.filter(filterToSelection).map(i => {
            return CellComponent
                ? (i.value === undefined ? '' : i.value.toString())
                : i.toString()
        }).join('\t')
    }

    function filterToSelection (cell, columnIndex) {
        if (columnIndex >= selectionRange.startColumn &&
            columnIndex <= selectionRange.endColumn) {
            return true
        } else {
            return false
        }
    }

    function headerRow (row, rowIndex) {
        return (
            <tr key={rowIndex} className={props.classes && props.classes.header && props.classes.header.tr}
                style={props.styles && props.styles.header && props.styles.header.tr}>
                {row.map((obj, ind) => headerCell(obj, ind))}
            </tr>
        )
    }

    function headerCell (cell, colIndex) {
        const columnIndex = props.numbered ? colIndex - 1 : colIndex
        return (
            <td key={columnIndex} className={props.classes && props.classes.header && props.classes.header.td}
                style={Object.assign({}, props.styles && props.styles.header && props.styles.header.td,
                    props.numbered && columnIndex === -1 && props.styles && props.styles.body && props.styles.body.numbered,
                    {
                        backgroundColor: columnIndex <= hover.column &&
                        columnIndex >= hover.column
                            ? props.hoverHeaderAndNumberedColor
                            : null
                    })}
                onMouseDown={(event) => gridMouseDown(event, null, columnIndex)}
                onMouseMove={(event) => gridMouseMove(event, null, columnIndex)}>
                {cell}
            </td>
        )
    }

    function bodyRow (row, rowIndex) {
        return (
            <tr key={rowIndex} className={props.classes && props.classes.header && props.classes.body.tr}
                style={props.styles && props.styles.body && props.styles.body.tr}>
                {props.numbered &&
                    <FrozenColumnTd className={props.classes && props.classes.header && props.classes.body.td}
                        style={Object.assign({}, props.styles && props.styles.body && props.styles.body.td,
                            props.styles && props.styles.body && props.styles.body.numbered,
                            {
                                backgroundColor: rowIndex <= hover.row &&
                                rowIndex >= hover.row
                                    ? props.hoverHeaderAndNumberedColor
                                    : null
                            })}
                        onMouseDown={(event) => gridMouseDown(event, rowIndex, null)}
                        onMouseMove={(event) => gridMouseMove(event, rowIndex, null)}>
                        {rowIndex - props.numberedOffset}
                    </FrozenColumnTd>
                }
                {row.map((obj, ind) => bodyCell(obj, ind, rowIndex))}
            </tr>
        )
    }

    function bodyCell (cell, columnIndex, rowIndex) {
        const Td = frozenColumns && columnIndex < frozenColumns ? FrozenColumnTd : ColumnTd
        if (editState.row === rowIndex && editState.column === columnIndex) {
            return (
                <ColumnTd key={columnIndex} className={props.classes && props.classes.body && props.classes.body.edit}
                    style={Object.assign({}, props.styles && props.styles.body && props.styles.body.edit,
                        {
                            padding: '0px'
                        })}
                    onMouseMove={(event) => gridMouseMove(event, rowIndex, columnIndex)}>
                    {EditCellComponent
                        ? <EditCellComponent
                            editorRef={editorRef}
                            editorValue={editState.current}
                            onChange={value => setEdit({ ...editState, current: value })}
                            closeEditor={closeEditor}
                            onKeyDown={editorKeyDown}
                            {...cell}></EditCellComponent>
                        : <input style={props.styles && props.styles.body && props.styles.body.edit}
                            ref={editorRef}
                            type='text'
                            value={editState.current}
                            onChange={(event) => setEdit({ ...editState, current: event.target.value })}
                            onKeyDown={editorKeyDown} />}
                </ColumnTd>
            )
        } else {
            return (
                <Td key={columnIndex} className={props.classes && props.classes.body && props.classes.body.td}
                    style={Object.assign({}, props.styles && props.styles.body && props.styles.body.td,
                        {
                            backgroundColor: rowIndex <= selectionRange.endRow &&
                            rowIndex >= selectionRange.startRow &&
                            columnIndex <= selectionRange.endColumn &&
                            columnIndex >= selectionRange.startColumn
                                ? rowIndex === initialSelectionCell.row &&
                                columnIndex === initialSelectionCell.column
                                    ? props.highlightInitialColor
                                    : props.highlightColor
                                : null
                        })}
                    onMouseMove={(event) => gridMouseMove(event, rowIndex, columnIndex)}
                    onMouseDown={(event) => gridMouseDown(event, rowIndex, columnIndex)}
                    onMouseUp={(event) => gridMouseUp(event, rowIndex, columnIndex)}>
                    {CellComponent ? <CellComponent {...cell} /> : cell}
                </Td>
            )
        }
    }

    function footerRow (row, rowIndex) {
        return (
            <tr key={rowIndex} className={props.classes && props.classes.footer && props.classes.footer.tr}
                style={props.styles && props.styles.footer && props.styles.footer.tr}>
                {row.map((obj, ind) => footerCell(obj, ind, rowIndex))}
            </tr>
        )
    }

    function footerCell (cell, columnIndex, rowIndex) {
        return (
            <td key={columnIndex} data={columnIndex} className={props.classes && props.classes.footer && props.classes.footer.td}
                style={Object.assign({}, props.styles && props.styles.footer && props.styles.footer.td,
                    {
                        backgroundColor: columnIndex <= selectionRange.endColumn &&
                        columnIndex >= selectionRange.startColumn &&
                        rowIndex <= selectionRange.endRow &&
                        rowIndex >= selectionRange.startRow
                            ? props.highlightColor
                            : null
                    })}>
                {cell}
            </td>
        )
    }

    return (
        // overflowX: 'auto' for freezing columns
        <div style={{ width: '100%', position: editState.row !== undefined && editState.column === 0 && 'relative' }}
            onKeyDown={componentKeyDown}
            onKeyUp={componentKeyUp}>
            <textarea ref={selectionRef}
                type='text'
                value={selection}
                style={{ position: 'fixed', zIndex: -1000, opacity: 0 }}
                readOnly
                onKeyDown={selectionKeyDown}
                onBlur={shouldHandleBlur ? selectionBlurred : undefined}
                onPaste={selectionPaste} />
            <table className={props.className}
                style={Object.assign({}, { cursor: 'cell' }, props.style)}
                onMouseUp={tableMouseUp}
                onMouseLeave={() => {
                    setHover({})
                }}>
                {props.header &&
                    <thead className={props.classes && props.classes.thead}
                        style={props.styles && props.styles.thead}>
                        {props.header.map(headerRow)}
                    </thead>
                }
                {body &&
                    <tbody className={props.classes && props.classes.tbody}
                        style={props.styles && props.styles.tbody}>
                        {body.map(bodyRow)}
                    </tbody>
                }
                {props.footer &&
                    <tfoot className={props.classes && props.classes.tfoot}
                        style={props.styles && props.styles.tfoot}>
                        {props.footer.map(footerRow)}
                    </tfoot>
                }
            </table>
        </div>
    )
}
