import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import easel from 'icons/easel.svg'

const StyledReviewButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
`

const StyledReviewButtonWithHover = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Review', -6)}
`

export default function ReviewButton (props) {
    if (props.hover) {
        return (
            <StyledReviewButtonWithHover {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={easel} alt='Review' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledReviewButtonWithHover>
        )
    } else {
        return (
            <StyledReviewButton {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={easel} alt='Review' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledReviewButton>
        )
    }
}
