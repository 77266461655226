import React from 'react'
import styled from 'styled-components'
import ObjectProperty from 'components/ObjectProperty'
import StyledTextarea from 'components/StyledTextarea'
import StyledInput from 'components/StyledInput'

const StyledObjectCreator = styled.div`
    width: 100%;
`

const StyledContainer = styled.div`
    display: flex;
`

const Category = styled(StyledInput)`
    margin-top: 2px;
    margin-bottom: 10px;
`

const Notes = styled(StyledTextarea)`
    min-height: 4rem;
    resize: vertical;
    margin-top: 2px;
    margin-bottom: 10px;
`

const Label = styled.div`
    font-weight: bold;
    margin-top: 4px;
`

export default function ObjectCreator (props: {
    info: {
        resolution: { solution?: string, special?: string, error?: string},
        name: string,
        text: string,
        mathml: string,
        context: Object,
        inheritedContext: Object,
        missingContext: Array<string>,
        requiredContext: Array<string>,
        solvableEquation: string
    },
    onContextChange: Function,
    category: string,
    onCategoryChange: Function,
    notes: string,
    onNotesChage: Function
}) {
    return (
        <StyledObjectCreator>
            <Label>Name</Label>
            <ObjectProperty id='base' info={props.info} onChange={props.onContextChange} />
            <Label>Category</Label>
            <StyledContainer>
                <Category value={props.category || ''} type='textarea' onChange={props.onCategoryChange} />
            </StyledContainer>
            <Label>Notes</Label>
            <StyledContainer>
                <Notes value={props.notes || ''} type='textarea' onChange={props.onNotesChange} />
            </StyledContainer>
        </StyledObjectCreator>
    )
}
