import * as React from 'react'
import styled from 'styled-components'

const TopBar = styled.div`
    width: 100%;
    cursor: pointer;
`

export default function Accordion (props: {
    label: string,
    expanded: boolean,
    toggle: Function
}) {
    const { className, label, expanded, toggle } = props
    return (
        <div className={className}>
            <TopBar onClick={() => toggle()}>
                {label}
            </TopBar>
            {expanded && props.children}
        </div>
    )
}
