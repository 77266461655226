import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import pickaxe from 'icons/pickaxe.svg'

const StyledMaterialButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
`

const StyledMaterialButtonWithHover = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Material', -9)}
`

export default function MaterialButton (props) {
    if (props.hover) {
        return (
            <StyledMaterialButtonWithHover {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={pickaxe} alt='Material' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledMaterialButtonWithHover>
        )
    } else {
        return (
            <StyledMaterialButton {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={pickaxe} alt='Material' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledMaterialButton>
        )
    }
}
