import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as styles from 'styles'
import premade from 'utils/premade.json'
import StyledInput from 'components/StyledInput'
import Accordion from 'components/Accordion'

const StyledFunctionSearch = styled.div`
    padding: 4px 0px;
    background: ${styles.extraLightGray};
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 4px 6px 4px #aaa;
    border-radius: 6px;
`

const Title = styled.div`
    padding-left: 4px;
    width: 500px;
    margin: 4px;
    font-size: 1.4rem;
    font-weight: bold;
`

const TitleSection = styled.div`
    padding-top: 4px;
    display: flex;
    cursor: pointer;
`

const Close = styled.div`
    width: 100%;
    padding: 4px;
    text-align: right;
    i {
        font-size: 2rem;
        border-radius: 20px;
        :hover {
            background: white;
        }
    }
`

const StyledItem = styled.div`
    display: flex;
    padding: 4px;
    a {
        color: black;
        text-decoration: none;
    }
    i {
        font-weight: bold;
        color: ${styles.extraLightGray};
    }
    :hover {
        i {
            color: red;
        }
    }
`

const DeleteButton = styled.div`
    i {
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        padding-top: 2px;
        :hover {
            position: relative;
            ::after {
                font-family: 'Ubuntu', sans-serif;
                font-weight: bold;
                content: "DELETE";
                position: absolute;
                top: -2px;
                right: -8px;
                background-color: ${styles.yellow};
                border-radius: 15px;
                padding: 5px 25px 5px 10px;
            }
        }
    }
`

const Label = styled.div`
    width: 100%;
    a {
        padding: 6px;
        border-radius: 10px;
        cursor: pointer;
        :hover {
            background: ${styles.lightYellow};
        }
    }
`

const SubSection = styled.div`
    font-weight: bold;
    padding: 8px;
    font-size: 1.2rem;
`

const NoLocal = styled.div`
    padding: 6px;
`

const SearchInput = styled(StyledInput)`
    margin: 4px;
    width: 264px;
    margin-bottom: 14px;
`

const CategoryLabel = styled.div`
    padding: 6px;
    font-weight: bold;
    padding-left: 8px;
    background-color: ${styles.lightGray};
`

function escapeRegExp (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function score ({ regexArray, name, url, category, notes }) {
    let score = 0
    regexArray.forEach(regex => {
        score += (name.match(regex) || { length: 0 }).length * 100
        score += (category.match(regex) || { length: 0 }).length * 50
        score += (url.match(regex) || { length: 0 }).length * 10
        score += (notes.match(regex) || { length: 0 }).length * 1
    })
    return score
}

function sortByScore (a, b) {
    if (a.sort > b.sort) {
        return -1
    } else if (a.sort < b.sort) {
        return 1
    } else {
        if (a.name < b.name) {
            return -1
        } else {
            return 1
        }
    }
}

// Takes in the functions object and returns an array of functions in order of relevance
function filterWithSort ({ listObject, filter }) {
    const keys = Object.keys(listObject)
    const regexArray = []
    // Try to use the filter as a regular if it's valid, otherwise use it as a string literal
    // And ignore case
    filter.split(' ').forEach(filterPart => {
        let regex
        try {
            regex = new RegExp(filterPart, 'gi')
        } catch (err) {
            regex = new RegExp(escapeRegExp(filterPart), 'gi')
        }
        regexArray.push(regex)
    })

    const equationCategories = {}
    keys.forEach(key => {
        const equation = listObject[key]
        const sort = score({ name: key, url: equation.url, category: equation.category || '', notes: equation.notes || '', regexArray })
        if (sort > 0) {
            if (!equationCategories[equation.category || 'None']) {
                equationCategories[equation.category || 'None'] = []
            }
            equationCategories[equation.category || 'None'].push({
                name: key,
                url: equation.url,
                category: equation.category,
                notes: equation.notes,
                sort
            })
        }
    }, [])
    const res = []
    Object.keys(equationCategories).forEach(key => {
        res.push({ name: key, equations: equationCategories[key].sort(sortByScore) })
    })
    return res
}

function SelfStateAccordion (props) {
    const { label } = props
    const [isOpen, setIsOpen] = useState(true)

    return <Accordion label={label} expanded={isOpen} toggle={() => setIsOpen(!isOpen)}>
        {props.children}
    </Accordion>
}

export default function FunctionSearch (props) {
    const { toggleSearch, deleteLocalFunction, localFunctions } = props
    const [filter, setFilter] = useState('')

    const searchElement = useRef()

    useEffect(() => {
        searchElement.current.focus()
    }, [])

    const filteredLocal = filterWithSort({ filter, listObject: localFunctions })
    const filteredPremade = filterWithSort({ filter, listObject: premade })

    function renderLocalEquation (equation) {
        return (
            <StyledItem key={equation.name}>
                <Label>
                    <Link to={equation.url}>{equation.name}</Link>
                </Label>
                <DeleteButton onClick={() => {
                    deleteLocalFunction(equation.name)
                }}>
                    <i className='material-icons'>close</i>
                </DeleteButton>
            </StyledItem>
        )
    }

    function renderLocalCategory ({ name, equations }) {
        return (
            <SelfStateAccordion key={name} label={<CategoryLabel>Category: {name}</CategoryLabel>}>
                {equations.map(renderLocalEquation)}
            </SelfStateAccordion>
        )
    }

    function renderPremadeEquation (equation) {
        return (
            <StyledItem key={equation.name}>
                <Label>
                    <Link to={equation.url}>{equation.name}</Link>
                </Label>
            </StyledItem>
        )
    }

    function renderPremadeCategory ({ name, equations }) {
        return (
            <SelfStateAccordion key={name} label={<CategoryLabel>Category: {name}</CategoryLabel>}>
                {equations.map(renderPremadeEquation)}
            </SelfStateAccordion>
        )
    }

    return (
        <StyledFunctionSearch>
            <TitleSection onClick={toggleSearch}>
                <Title>
                    Search Vault
                </Title>
                <Close onClick={toggleSearch}>
                    <i className='material-icons'>close</i>
                </Close>
            </TitleSection>
            <SearchInput
                ref={searchElement}
                placeholder='Filter'
                value={filter}
                onChange={event => setFilter(event.target.value)}
            />
            {filteredLocal.length === 0
                ? <NoLocal>Your personal vault is empty</NoLocal>
                : null
            }
            {filteredLocal.map(renderLocalCategory)}
            {filteredPremade.length > 0
                ? <SubSection>Standard Arsenal</SubSection>
                : null
            }
            {filteredPremade.map(renderPremadeCategory)}
        </StyledFunctionSearch>
    )
}
