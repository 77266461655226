import styled from 'styled-components'

export default styled.div`
    position: fixed;
    bottom: 15px;
    right: 5px;
    i {
        font-size: 3rem;
    }
    div {
        display: flex;
    }
`
