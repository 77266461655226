export const primaryColor = '#0064ff' // #0082ff
export const secondaryColor = '#0ccf35'
export const complementaryColor = '#fe8500'

export const yellow = '#FC0'
export const lightYellow = '#FC06'

export const lightGreen = `${secondaryColor}40`
export const extraLightGreen = `${secondaryColor}20`

export const lightOrange = '#ffe1b5'
export const extraLightOrange = '#ffebcdcc'

export const lightBlue = 'lightblue'

export const gray = '#888888'
export const lightGray = '#CCC'
export const extraLightGray = '#EEE'

export const backgroundColor = '#777'

export const materialColumnWidth = 168

export const materialIterationWidth = 80

export const inputFocus = extraLightGreen
export const inputShadow = `${lightGray}`

export const inputStyle = `
    font-family: 'Ubuntu', sans-serif;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 4px 6px;
    border-radius: 4px;
    width: 100%;
    border: none;
    box-shadow: 0px 0px 2px 2px ${inputShadow};
    outline: none;
    :focus {
        background-color: ${inputFocus};
    }
`

export function buttonHover (message, offset) {
    return `
    :hover {
        border: white 5px solid;
        margin: -5px;
        position: relative;
        ::after {
            box-shadow: 0px 3px 3px #CCC;
            font-weight: bold;
            content: "${message}";
            position: absolute;
            top: 45px;
            right: ${offset}px;
            background-color: white;
            border-radius: 15px;
            padding: 5px;
            @media (max-width: 600px) {
                right: 0px;
            }
        }
    }
`
}
