import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import originalStyledInput from 'components/StyledInput'

const StyledInput = styled(originalStyledInput)`
    width: 80px;
`

const StyledTableGenerator = styled.div`
    display: flex;
    flex-direction: column;
    input {
        text-align: center;
    }
`

const StyledOverallSection = styled.div`
    display: flex;
    width: 100%;
    padding-top: 1rem;
`

const StyledColumnSection = styled.div`
    padding-left: ${styles.materialIterationWidth + 2}px;
    display: flex;
    padding-bottom: 1rem;
`

const StyledSettingGroup = styled.div`
    width: ${styles.materialColumnWidth}px;
    padding: 2px;
    text-align: right;
`

const StackedColumn = styled.div`
    flex-direction: column;
`

const Setting = styled.div`
    display: flex;
    padding: 2px;
`

const Label = styled.div`
    padding-top: .2rem;
    padding-right: 1rem;
`

export default function TableGenerator (props) {
    const {
        columns,
        columnColorObject,
        columnSettings,
        settings = {},
        onGeneratorSettingChange,
        onColumnSettingChange
    } = props
    const left = settings.left
    const right = settings.right

    function renderColumns ({ columns, columnColorObject, columnSettings, onColumnSettingChange }) {
        const changeColumnSetting = (columnName, settingName) => {
            return function (event) {
                const newValue = event.target.value
                if (!columnSettings[columnName] || newValue !== columnSettings[columnName][settingName]) {
                    const newColumnSettings = { ...columnSettings }
                    if (!columnSettings[columnName]) {
                        newColumnSettings[columnName] = {}
                    } else {
                        newColumnSettings[columnName] = { ...columnSettings[columnName] }
                    }
                    newColumnSettings[columnName][settingName] = newValue
                    onColumnSettingChange(newColumnSettings)
                }
            }
        }
        return columns.map(column => {
            const option = columnSettings[column] ? columnSettings[column] : {}
            const start = option.start
            const interval = option.interval
            return <StyledSettingGroup key={column}>
                <div style={{ color: columnColorObject[column], fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }}>{column}</div>
                <StackedColumn>
                    <Setting>
                        <Label>Start</Label>
                        <StyledInput style={{ marginLeft: 18 }} type='number' onChange={changeColumnSetting(column, 'start')} value={start || ''} placeholder='0' />
                    </Setting>
                    <Setting>
                        <Label>Interval</Label>
                        <StyledInput type='number' onChange={changeColumnSetting(column, 'interval')} value={interval || ''} placeholder='1' />
                    </Setting>
                </StackedColumn>
            </StyledSettingGroup>
        })
    }

    function changeSetting (settingName) {
        return function (event) {
            const newValue = event.target.value.replace(/-/g, '')
            if (newValue !== settings[settingName]) {
                const newSetting = { ...settings }
                newSetting[settingName] = newValue
                onGeneratorSettingChange(newSetting)
            }
        }
    }
    return (
        <StyledTableGenerator>
            <StyledOverallSection>
                <Setting style={{ paddingRight: 8 }}>
                    <Label>Left</Label>
                    <StyledInput type='number' onChange={changeSetting('left')} value={left || ''} placeholder='0' />
                </Setting>
                <Setting>
                    <Label>Right</Label>
                    <StyledInput type='number' onChange={changeSetting('right')} value={right || ''} placeholder='10' />
                </Setting>
            </StyledOverallSection>
            <StyledColumnSection>
                {renderColumns({ columns, columnColorObject, columnSettings, onColumnSettingChange })}
            </StyledColumnSection>
        </StyledTableGenerator>
    )
}
