import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'

const StyledSaveButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Save')}
`

export default function SaveButton (props) {
    if (props.text && props.location) {
        return (
            <StyledSaveButton {...props}>
                <i className='material-icons'>save</i>
            </StyledSaveButton>
        )
    } else {
        return null
    }
}
