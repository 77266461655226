import { useEffect, useState } from 'react'

export function getLocalStorage (key) {
    const localString = localStorage.getItem(key)
    return JSON.parse(localString)
}

export function setLocalStorage (key, value) {
    const valueString = JSON.stringify(value)
    localStorage.setItem(key, valueString)
}

export function useLocalStorage (defaultValue, key) {
    const [state, setState] = useState(defaultValue)

    useEffect(() => {
        const value = getLocalStorage(key)
        if (value !== undefined && value !== null) {
            setState(value)
        }
    }, [key])

    return [
        state,
        newValue => {
            setLocalStorage(key, newValue)
            setState(newValue)
        }
    ]
}
