import React from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import anvil from 'icons/anvil.svg'

const StyledForgeButton = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
`

const StyledForgeButtonWithHover = styled.div`
    color: black;
    cursor: pointer;
    border-radius: 30px;
    ${styles.buttonHover('Forge', -2)}
`

export default function ForgeButton (props) {
    if (props.hover) {
        return (
            <StyledForgeButtonWithHover {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={anvil} alt='Forge' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledForgeButtonWithHover>
        )
    } else {
        return (
            <StyledForgeButton {...props} style={{ ...props.style, backgroundColor: props.active ? styles.primaryColor : undefined }}>
                <img src={anvil} alt='Forge' height={(props.style && props.style.height) || 50} width={(props.style && props.style.width) || 50} />
            </StyledForgeButton>
        )
    }
}
