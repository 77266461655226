import React from 'react'
import styled from 'styled-components'
import getColor from 'utils/math/getColor'
import { getVariables, formatNumber } from 'utils/math'
import StyledInput from 'components/StyledInput'

const StyledContainer = styled.div`
    display: flex;
`

const Label = styled.span`
    font-weight: bold;
`

function getNewContext ({ previousText, text, context }) {
    const newContext = { ...context }
    const previousVariables = getVariables({ text: previousText })
    const nextVariables = getVariables({ text })
    // if there is only one difference between the list of previous and next variables use the old value as the new
    const unusedPrevious = previousVariables.filter(i => !nextVariables.includes(i))
    const unusedNext = nextVariables.filter(i => !previousVariables.includes(i))
    if (newContext) {
        if (unusedPrevious.length === 1 && unusedNext.length === 1 && unusedPrevious[0]) {
            newContext[unusedNext[0]] = newContext[unusedPrevious[0]]
            delete newContext[unusedPrevious[0]]
        } else {
            // clear unused context
            Object.keys(newContext).forEach(variable => {
                if (!nextVariables.includes(variable)) {
                    delete newContext[variable]
                }
            })
        }
    }
    return newContext
}

function NestedProperty ({ id, variable, info, onChange }: {
    id: string,
    variable: string,
    info: {
        resolution: { solution?: string, special?: string, error?: string},
        name: string,
        text: string,
        context: Object,
        inheritedContext: Object,
        missingContext: Array<string>,
        requiredContext: Array<string>
    },
    onChange: Function
}) {
    const newInfo = {
        resolution: {},
        text: '',
        missingContext: [],
        requiredContext: [],
        ...info.context[variable],
        inheritedContext: { ...info.inheritedContext, ...info.context },
        name: variable
    }
    return (
        <ObjectProperty
            id={`${id}-${variable}`}
            info={newInfo}
            onChange={({ name, text, context }) => {
                const copyContext = { ...info.context }
                if (!copyContext[name]) copyContext[name] = {}
                copyContext[name].context = context
                copyContext[name].text = text
                onChange({ name: info.name, text: info.text, context: copyContext })
            }} />
    )
}

export default function ObjectProperty (props: {
    id: string,
    info: {
        resolution: { solution?: string, special?: string, error?: string},
        name: string,
        text: string,
        context: Object,
        inheritedContext: Object,
        missingContext: Array<string>,
        requiredContext: Array<string>
    },
    onChange: Function
}) {
    const {
        id,
        info,
        onChange
    } = props
    // const placeholder = info.inheritedContext &&
    // info.inheritedContext[info.name] &&
    // info.inheritedContext[info.name].resolution &&
    // info.inheritedContext[info.name].resolution.solution ?
    // info.inheritedContext[info.name].resolution.solution : undefined
    const nameStyle = { marginTop: 7, marginBottom: 2, color: getColor(info.name) }

    return (
        <div>
            <div>
                <div style={nameStyle}>
                    {info.name
                        ? <Label>{info.name} =&nbsp;
                            {info.resolution.solution
                                ? formatNumber(info.resolution.solution)
                                : null}
                        </Label>
                        : null}
                </div>
                <StyledContainer>
                    <StyledInput type='text'
                        placeholder='Name / Value / Equation'
                        style={{ marginBottom: 5 }}
                        value={info.text || ''}
                        onChange={event => {
                            let text = event.target.value
                            if (text.trim() === '') {
                                text = undefined
                            }
                            onChange({ name: info.name, text, context: getNewContext({ previousText: info.text, text, context: info.context }) })
                        }} />
                </StyledContainer>
            </div>
            {info.requiredContext && info.requiredContext.map(variable => <NestedProperty key={`${id}-${variable}`} id={id} variable={variable} info={info} onChange={onChange} />)}
        </div>
    )
}
