import React, { useState } from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import Draw from 'components/Draw'
import StyledInput from 'components/StyledInput'

const StyledDrawView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;

    canvas {
        border: 1px lightblue solid;
    }

    input {
        margin-left: 20px;
        margin-right: 20px;
        @media (max-width: 992px) {
            margin-left: 4px;
            margin-right: 4px;
            width: 50px;
        }
    }
`

const StyledDrawControls = styled.div`
    position: fixed;
    top: 1rem;
    right: 0px;
    max-width: 600px;
    display: flex;
`

const StyledLabel = styled.label`
    margin: 5px;
    white-space: nowrap;
    width: 35px;
    padding-right: 20px;
`

const StyledNumberInput = styled(StyledInput)`
    text-align: center;

    :focus {
        background-color: ${styles.extraLightGray};
    }
`

const StyledButton = styled(StyledInput)`
    cursor: pointer;
    width: 200px;

    :focus {
        background-color: #CCC;
    }
`

export default function DrawView (props) {
    const [context, setContext] = useState()
    const width = window.innerWidth - 30
    const height = window.innerHeight > 500 ? window.innerHeight - 100 : window.innerHeight - 40
    const [penWidth, setPenWidth] = useState()
    const [penColor, setPenColor] = useState('#000000')

    function clearImage () {
        context.clearRect(0, 0, width, height)
    }

    function handleSetContext (context) {
        setContext(context)
        localStorage.setItem('imageDataUrl', context.canvas.toDataURL())
    }

    const imageDataUrl = localStorage.getItem('imageDataUrl')
    return (
        <StyledDrawView>
            <StyledDrawControls>
                <StyledButton
                    className='btn'
                    type='button'
                    value='Clear'
                    onClick={() => clearImage()} />
                <StyledLabel>Pen Size:</StyledLabel>
                <StyledNumberInput
                    type='number'
                    value={penWidth || ''}
                    placeholder={1}
                    onChange={event => setPenWidth(event.target.value)} />
                <StyledInput
                    type='color'
                    value={penColor}
                    onChange={event => setPenColor(event.target.value)} />
            </StyledDrawControls>
            <Draw
                imageDataUrl={imageDataUrl}
                width={width}
                penType='round'
                height={height}
                penColor={penColor}
                penWidth={penWidth || 1}
                setContext={handleSetContext} />
        </StyledDrawView>
    )
}
